import React from 'react'
import PremiumBenefits from '../../components/PremiumBenefits'
import { useGuild } from 'app/frontend/base/config/context/guild'

const GuildPremium = () => {
  const { guild } = useGuild()

  return (
    <div>
      <h2 className='font-bold text-2xl mb-4'>Premium</h2>

      {'premiumBenefits' in guild ? (
        <PremiumBenefits
          guild={guild}
          legacyTier={guild?.legacyPatron?.details.pledge_tier}
          benefits={guild?.premiumBenefits || []}
        />
      ) : null}
    </div>
  )
}

export default GuildPremium
