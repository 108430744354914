import { LegacyPledgeTier, SubscriptionTier } from 'app/frontend/types/enums'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { premiumColors, useSubscription } from '../helpers'
import { Check, CheckCircle, Star } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { usePageInfo } from '../config/queries'
import PremiumBenefitsPreviewModal from './PremiumBenefitsPreviewModal'

interface Props {
  tierName: LegacyPledgeTier | SubscriptionTier
  active: boolean
  setClicked: (clicked: boolean) => void
}

interface TierInfo {
  price: string
  benefits: string[]
  example: { label: string; caption: string }[]
}

const checkoutUrl = {
  Rare: 'https://www.patreon.com/join/auditspreadsheet/checkout?rid=1415200',
  Epic: 'https://www.patreon.com/join/auditspreadsheet/checkout?rid=4489422',
  Legendary:
    'https://www.patreon.com/join/auditspreadsheet/checkout?rid=1772543',
}

const PremiumCard = (props: Props) => {
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()
  const { subscriptionActive } = useSubscription(user)

  const legacy =
    props.active &&
    ['Bronze', 'Silver', 'Gold', 'Platinum'].includes(props.tierName)

  const tierInfo: TierInfo = t(
    `premium.tiers.${props.tierName.toLowerCase()}`,
    {
      returnObjects: true,
    },
  )
  const tierColors = premiumColors[props.tierName]

  return (
    <div
      className={`relative bg-muted basis-1/3 py-3 px-3 rounded-md border-2 flex flex-col ${
        props.active ? tierColors.border : 'border-transparent'
      }`}
    >
      <h2
        className={`${tierColors.text} font-bold text-lg justify-center mb-2 flex items-center gap-2`}
      >
        <Star size={18} /> {props.tierName}
      </h2>

      {props.active ? (
        <div className='absolute right-3 top-3.5'>
          <CheckCircle className='text-primary' />
        </div>
      ) : null}

      <ul className='grow'>
        {tierInfo.benefits.map((benefit, i) => (
          <li className='flex gap-2 mb-1' key={i}>
            <Check size={14} className='text-primary mt-1 min-w-[1rem]' />
            <span>{benefit}</span>
          </li>
        ))}
      </ul>

      <div className='bg-accent rounded-md px-3 py-2 mt-4'>
        <h3 className='font-bold mb-1 flex justify-between'>
          <span>
            {t(legacy ? 'premium.legacy-example' : 'premium.example')}
          </span>
          {legacy ? null : (
            <PremiumBenefitsPreviewModal
              tierName={props.tierName as SubscriptionTier}
            >
              <Button variant='outline' size='xs'>
                {t('shared.preview').toLowerCase()}
              </Button>
            </PremiumBenefitsPreviewModal>
          )}
        </h3>
        <ul>
          {tierInfo.example.map((example, i) => (
            <li key={i} className='flex justify-between gap-4'>
              <span className='flex gap-2 mb-1'>
                <Check
                  size={14}
                  className='block text-primary mt-1 min-w-[1rem]'
                />
                {example.label}
              </span>
              <span className='italic opacity-50 whitespace-nowrap'>
                {example.caption}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className='flex justify-between items-center mt-4'>
        <span className='flex items-center gap-2'>
          <span className='text-4xl'>${tierInfo.price}</span>
          <span className='w-12 leading-4'>{t('premium.per-month')}</span>
        </span>

        <div className='flex justify-center'>
          <a
            href={
              props.active
                ? 'https://support.patreon.com/hc/en-us/articles/360005502572-Canceling-a-paid-membership'
                : checkoutUrl[props.tierName as 'Rare' | 'Epic' | 'Legendary']
            }
            target='_blank'
            rel='noreferrer'
          >
            {props.active ? (
              <Button size='sm' variant='destructive'>
                {t('premium.cancel-plan')}
              </Button>
            ) : (
              <Button size='sm' onClick={() => props.setClicked(true)}>
                {t(
                  subscriptionActive
                    ? 'premium.change-plan'
                    : 'premium.checkout-new',
                )}
              </Button>
            )}
          </a>
        </div>
      </div>
    </div>
  )
}

export default PremiumCard
