export const itemQualities = [
  'poor',
  'common',
  'uncommon',
  'rare',
  'epic',
  'legendary',
  'artifact',
  'heirloom',
] as const

export const itemQualityClasses = [
  'text-quality-poor',
  'text-quality-common',
  'text-quality-uncommon',
  'text-quality-rare',
  'text-quality-epic',
  'text-quality-legendary',
  'text-quality-artifact',
  'text-quality-heirloom',
] as const

type ItemQuality = typeof itemQualities[number]
export default ItemQuality
