import React from 'react'
import { Main } from '../../components'

const UserDashboard = () => {
  return (
    <Main>
      <div>UserDashboard!</div>
    </Main>
  )
}

export default UserDashboard
