import { getRequest } from '.'

export const getSearchResults = async (
  terms: string,
  type: 'internal' | 'external',
  realmScope?: wowaudit.Realm,
): Promise<(wowaudit.Character | wowaudit.Guild | wowaudit.Community)[]> => {
  return await getRequest(
    type === 'internal'
      ? `/api/search?terms=${terms}${
          realmScope ? `&realm_id=${realmScope.id}` : ''
        }`
      : `/api/search/blizzard?name=${terms}&realm_id=${realmScope?.id}`,
  )
}

export const getEntityFromBlizzard = async (
  name: string,
  realmName: string,
  region: string,
  type: 'Character' | 'Guild',
) => {
  return await getRequest(
    `/api/search/blizzard?name=${name}&realm_name=${realmName}&region=${region}&type=${type}`,
  )
}
