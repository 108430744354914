import React from 'react'
import { Main } from '../../components'

const TeamDashboard = () => {
  return (
    <Main>
      <div>TeamDashboard!</div>
    </Main>
  )
}

export default TeamDashboard
