import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'

interface Props {
  team: wowaudit.Team
}

const TeamCard = ({ team }: Props) => {
  const navigate = useNavigate()

  return (
    <div key={team.id} className='bg-accent my-1 py-1 px-2 rounded-md'>
      <div>
        <a
          href={team.ownerPath}
          target='_blank'
          rel='noreferrer'
          className='hover:text-primary transition-colors'
        >
          {team.ownerName}
        </a>
        <span> - </span>
        <Link
          to={team.path}
          state={{ team }}
          className='hover:text-primary transition-colors'
        >
          {team.name}
        </Link>
      </div>
      <div className='flex items-center justify-between'>
        <span className='italic opacity-70'>
          {team.characterAmount} characters
        </span>

        <Button variant='outline' size='xs' onClick={() => navigate(team.path)}>
          view
        </Button>
      </div>
    </div>
  )
}

export default TeamCard
