import React from 'react'
import DashboardTile from '../../../components/DashboardTile'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import Subheader from 'app/frontend/base/components/Subheader'
import { useTranslation } from 'react-i18next'
import TeamSwitcher from '../../TeamDashboard/components/TeamSwitcher'
import HeaderTiles from '../../TeamDashboard/components/HeaderTiles'
import TeamSubheader from '../../TeamDashboard/components/TeamSubheader'
import { Link } from 'react-router-dom'
import { useGuild } from 'app/frontend/base/config/context/guild'
dayjs.extend(RelativeTime)

interface Props {
  uriName: string
  uriRegion: string
  uriRealm: string
}

const GuildHeader = ({ uriName, uriRegion, uriRealm }: Props) => {
  const { guild, currentTeam } = useGuild()

  const { t } = useTranslation()
  const uriLinkFragment = `${uriRegion}/${uriRealm}/${uriName}`
  const basePath = `${guild?.path ? guild.path : `/guild/${uriLinkFragment}`}`

  return (
    <React.Fragment>
      <header className='border-b-2 ml-[-2rem] mr-[-2rem]'>
        <div className='flex items-end mt-[-2rem] ml-[2rem] mr-[2rem]'>
          <figure
            className={`w-[7rem] h-[7rem] bg-cover mr-4`}
            style={{
              backgroundImage: `url(https://data.wowaudit.com/crests/${
                guild?.bannerFilename ||
                `default-${(guild?.faction || 'Alliance').toLowerCase()}.png`
              })`,
            }}
          ></figure>

          <div className='grow mb-2'>
            <div
              className={`flex ${currentTeam ? 'items-end gap-4' : 'flex-col'}`}
            >
              <Link to={basePath} state={{ guild, team: undefined }}>
                <h1>
                  <span
                    className={`font-bold text-[2rem] capitalize ${
                      guild?.faction === 'Horde'
                        ? 'text-faction-horde'
                        : guild?.faction === 'Alliance'
                        ? 'text-faction-alliance'
                        : ''
                    }`}
                  >
                    {guild?.name || uriName}
                  </span>
                </h1>
              </Link>

              <span
                className={`text-sm capitalize h-[1.6rem] ${
                  currentTeam ? 'mb-1' : ''
                }`}
              >
                {guild?.realm?.nameWithRegion ||
                  `${uriRealm} (${uriRegion.toUpperCase()})`}
              </span>
            </div>

            {currentTeam ? (
              <span className='h-[1.6rem]'>
                <TeamSwitcher />
              </span>
            ) : null}
          </div>

          {currentTeam ? (
            <HeaderTiles />
          ) : (
            <>
              <DashboardTile
                innerClassName='h-[2rem]'
                title='members'
                dataPresent={true}
              >
                <div>{guild?.guildMembers || '-'}</div>
              </DashboardTile>

              <DashboardTile
                innerClassName='h-[2rem]'
                title='founded'
                dataPresent={true}
              >
                <div>
                  {guild?.foundedAt ? dayjs(guild?.foundedAt).fromNow() : '-'}
                </div>
              </DashboardTile>
            </>
          )}

          <DashboardTile
            innerClassName='h-[2rem]'
            title='links'
            dataPresent={true}
          >
            <div className='flex gap-2'>
              {currentTeam ? (
                <a href={`${currentTeam.path}/spreadsheet`}>
                  <img
                    src='https://data.wowaudit.com/img/sheets-logo.png'
                    className='w-6'
                  />
                </a>
              ) : null}
              <a
                href={
                  guild?.blizzardUrl ||
                  `https://worldofwarcraft.blizzard.com/en-gb/guild/${uriLinkFragment}`
                }
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://data.wowaudit.com/img/wow-logo.png'
                  className='w-6'
                />
              </a>
              <a
                href={
                  guild?.wclUrl ||
                  `https://www.warcraftlogs.com/guild/${uriLinkFragment}`
                }
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://data.wowaudit.com/img/wcl-logo.png'
                  className='w-6'
                />
              </a>
              <a
                href={
                  guild?.raiderioUrl ||
                  `https://raider.io/guilds/${uriLinkFragment}`
                }
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://data.wowaudit.com/img/raiderio-logo.png'
                  className='w-6'
                />
              </a>
            </div>
          </DashboardTile>
        </div>
      </header>

      {currentTeam ? (
        <TeamSubheader />
      ) : guild?.id ? (
        <Subheader
          menuItems={[
            { name: t('shared.overview'), path: basePath },
            { name: t('shared.teams'), path: `${basePath}/teams` },
            { name: t('shared.news'), path: `${basePath}/news` },
            { name: t('shared.members'), path: `${basePath}/members` },
            { name: t('shared.premium'), path: `${basePath}/premium` },
          ]}
          state={{ guild }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default GuildHeader
