import { Button } from '@/components/ui/button'
import { useGuild } from 'app/frontend/base/config/context/guild'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const GuildTeams = () => {
  const { guild } = useGuild()
  const { t } = useTranslation()

  return (
    <div>
      <h2 className='font-bold text-2xl mb-4 flex justify-between items-center'>
        <span>{t('shared.teams')}</span>
        <Button size='sm'>{t('shared.create-new')}</Button>
      </h2>

      <div className='flex flex-col gap-2 text-sm'>
        {(guild?.teams || []).map((team) => (
          <div
            className='bg-muted rounded-md py-3 px-4 flex items-center justify-between gap-6'
            key={team.id}
          >
            <div>
              <Link to={team.path} state={{ team }}>
                <h3 className='font-semibold text-lg'>{team.name}</h3>
              </Link>
              <p className={team.description ? '' : 'opacity-50 italic'}>
                {team.description || t('shared.no-description')}
              </p>
            </div>

            <div className='flex gap-4 items-center'>
              <span>{team.characterAmount} members</span>
              <Link to={team.path} state={{ team }}>
                <Button variant='outline' size='sm'>
                  {t('shared.view')}
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GuildTeams
