import Subheader from 'app/frontend/base/components/Subheader'
import { useGuild } from 'app/frontend/base/config/context/guild'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TeamSubheader = () => {
  const { t } = useTranslation()
  const { currentTeam } = useGuild()

  if (!currentTeam) return null

  return (
    <Subheader
      menuItems={[
        { name: t('shared.overview'), path: `${currentTeam.path}` },
        { name: t('shared.news'), path: `${currentTeam.path}/news` },
        { name: t('shared.roster'), path: `${currentTeam.path}/roster` },
        { name: t('shared.events'), path: `${currentTeam.path}/events` },
        { name: t('shared.loot'), path: `${currentTeam.path}/loot` },
        {
          name: t('shared.spreadsheet'),
          path: `${currentTeam.path}/spreadsheet`,
        },
        {
          name: t('shared.applicants'),
          path: `${currentTeam.path}/applicants`,
        },
        { name: t('shared.settings'), path: `${currentTeam.path}/settings` },
      ]}
      state={{ team: currentTeam }}
    />
  )
}

export default TeamSubheader
