import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import UserBenefitsBar from './UserBenefitsBar'
import { Button } from '@/components/ui/button'
import { uniqBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { revokeSubscriptionBenefits } from 'app/frontend/base/api/user'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  subscription: wowaudit.Subscription
  color: string
}

const SubscriptionInfo = ({ subscription, color }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [revoking, setRevoking] = useState<number>()

  const pointsSpentByEntity = useMemo(() => {
    const aggregate: { [recipientId: number]: number } = {}

    for (const benefit of subscription.premiumBenefits) {
      if (!aggregate[benefit.recipientId]) {
        aggregate[benefit.recipientId] = 0
      }

      aggregate[benefit.recipientId] += benefit.points
    }

    return aggregate
  }, [subscription])

  const beneficiaries = uniqBy(subscription.premiumBenefits, 'recipientId')

  return (
    <div>
      <h3>
        You are subscribed to the{' '}
        <strong className={`${color}`}>{subscription.tierName}</strong> plan
      </h3>

      <UserBenefitsBar
        points={subscription.points}
        pointSpentPerEntity={Object.values(pointsSpentByEntity)}
        label='points'
      />

      <div className='bg-accent py-2 px-3 rounded-md'>
        <h3 className='mb-2'>Active benefits</h3>
        <ul className='text-sm'>
          {beneficiaries.length === 0 && (
            <p className='italic opacity-70'>
              {t('profile.premium.nothing-granted')}
            </p>
          )}
          {beneficiaries.map((beneficiary) => {
            const pointsSpent = pointsSpentByEntity[beneficiary.recipientId]

            return (
              <li
                key={beneficiary.recipientId}
                className=' mb-2 flex justify-between bg-muted py-2 px-3 rounded-md'
              >
                <span>{beneficiary.recipientName}</span>
                <div className='flex items-center gap-4'>
                  <span>
                    {pointsSpent} point{pointsSpent === 1 ? '' : 's'}
                  </span>
                  <div className='flex gap-2'>
                    <Link
                      to={`${beneficiary.recipientPath}/premium`}
                      state={{
                        guild: {
                          id: beneficiary.recipientId,
                          name: beneficiary.recipientName,
                          path: beneficiary.recipientPath,
                        },
                      }}
                    >
                      <Button variant='outline' size='xs'>
                        {t('shared.view')}
                      </Button>
                    </Link>

                    <Button
                      variant='outline'
                      size='xs'
                      loading={revoking === beneficiary.recipientId}
                      onClick={() => {
                        setRevoking(beneficiary.recipientId)
                        revokeSubscriptionBenefits(
                          beneficiary.recipientId,
                          beneficiary.recipientType,
                        )
                          .then(async () => {
                            await queryClient.invalidateQueries({
                              queryKey: ['pageInfo'],
                            })
                            setRevoking(undefined)
                          })
                          .catch((err) => {
                            displayErrorToast(err, t)
                            setRevoking(undefined)
                          })
                      }}
                    >
                      {t('shared.revoke')}
                    </Button>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default SubscriptionInfo
