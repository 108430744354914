import { Separator } from '@/components/ui/separator'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LogoIcon from 'public/images/logo-icon.svg?react'
import PremiumModal from './PremiumModal'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className='border-t bg-muted'>
      <div className='container mt-4 mb-4 flex items-center justify-between'>
        <div>
          <Link to='/' className='mr-6 h-10 block'>
            <LogoIcon className='w-10 h-10' color='hsl(var(--primary))' />
          </Link>
        </div>
        <div className='flex items-center gap-3 text-sm'>
          <a href='https://discord.gg/86eUAFz' target='_blank' rel='noreferrer'>
            {t('footer.support')}
          </a>
          <Separator orientation='vertical' className='h-2.5' />

          <PremiumModal>
            <a className='cursor-pointer'>{t('shared.subscribe')}</a>
          </PremiumModal>

          <Separator orientation='vertical' className='h-2.5' />
          <Link to='/api'>{t('footer.api')}</Link>
          <Separator orientation='vertical' className='h-2.5' />
          <Link to='/privacy-policy'>{t('footer.privacy-policy')}</Link>
          <Separator orientation='vertical' className='h-2.5' />
          <Link to='/terms-of-use'>{t('footer.terms-of-use')}</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
