import React, { useContext, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { DataTable } from '@/components/ui/data-table'
import { ColumnDef } from '@tanstack/react-table'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { Star } from 'lucide-react'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { updatePersonalCharacter } from 'app/frontend/base/api/user'
import { AppContext } from 'app/frontend/base/config/context/app'
import { useTranslation, Trans } from 'react-i18next'
import { Button } from '@/components/ui/button'
import TransferModal from './TransferModal'
import TroubleshootingModal from './TroubleshootingModal'
import { useUserCharacterDetails } from 'app/frontend/base/config/queries'
import {
  characterClass,
  itemLevel,
  level,
  name,
  realm,
  refreshedAt,
  teams,
} from 'app/frontend/base/config/column-definitions/character'

interface Props {
  characters: wowaudit.Character[]
  actionable: boolean
}

const CharacterTable = (props: Props) => {
  const { setCurrentCharacter } = useContext(AppContext)
  const queryClient = useQueryClient()
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [transferringCharacter, setTransferringCharacter] =
    useState<wowaudit.Character>()
  const [loadingMain, setLoadingMain] = useState<number>()
  const [loadingAction, setLoadingAction] = useState<{
    id: number
    action: 'inactive' | 'deleted' | 'transferred'
  }>()
  const { t } = useTranslation()

  const { data: userCharacterDetails } = useUserCharacterDetails(
    !props.actionable,
  )

  const setMain = (character: wowaudit.Character) => {
    if (character.main || loadingMain) return
    setLoadingMain(character.id)

    updatePersonalCharacter(character.id, { main: true })
      .then(async () => {
        await queryClient.invalidateQueries({ queryKey: ['pageInfo'] })
        setCurrentCharacter(character)
      })
      .catch((err) => {
        displayErrorToast(err, t)
      })
      .then(() => setLoadingMain(undefined))
  }

  const processAction = (
    character: wowaudit.Character,
    action: 'inactive' | 'deleted',
  ) => {
    setLoadingAction({ id: character.id, action })

    updatePersonalCharacter(character.id, { gdprStatus: action })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['pageInfo'] })
      })
      .catch((err) => {
        displayErrorToast(err, t)
      })
      .then(() => setLoadingAction(undefined))
  }

  const CharacterActions = (character: wowaudit.Character) => (
    <React.Fragment>
      <Button
        variant='outline'
        size='sm'
        onClick={() => setTransferringCharacter(character)}
        disabled={loadingAction?.id === character.id}
      >
        transferred
      </Button>
      <Button
        variant='outline'
        size='sm'
        onClick={() => processAction(character, 'inactive')}
        loading={
          loadingAction?.id === character.id &&
          loadingAction?.action === 'inactive'
        }
        disabled={loadingAction?.id === character.id}
      >
        inactive
      </Button>
      <Button
        variant='outline'
        size='sm'
        onClick={() => processAction(character, 'deleted')}
        loading={
          loadingAction?.id === character.id &&
          loadingAction?.action === 'deleted'
        }
        disabled={loadingAction?.id === character.id}
      >
        deleted
      </Button>
    </React.Fragment>
  )

  const columns: ColumnDef<wowaudit.Character>[] = useMemo(
    () => [
      {
        accessorKey: 'main',
        id: 'main',
        header: '',
        size: 0,
        cell: (info) => {
          const active =
            (info.getValue() && !loadingMain) ||
            loadingMain === info.row.original.id
          return (
            <div
              className='flex items-end justify-end mr-[-1rem]'
              onClick={() => setMain(info.row.original)}
            >
              <Tooltip delayDuration={150}>
                {info.getValue() ? <TooltipContent>Main</TooltipContent> : null}
                <TooltipTrigger>
                  <Star
                    size={18}
                    fill={active ? 'rgb(234, 179, 8)' : 'transparent'}
                    className={`cursor-pointer ${
                      active
                        ? 'text-yellow-500'
                        : 'opacity-20 hover:text-yellow-500 hover:opacity-100'
                    }`}
                  />
                </TooltipTrigger>
              </Tooltip>
            </div>
          )
        },
      },
      name,
      characterClass,
      realm,
      level,
      itemLevel(userCharacterDetails || []),
      teams(t, userCharacterDetails || []),
      refreshedAt(t, 'user', CharacterActions),
      {
        id: 'actionableReason',
        cell: () => {
          return (
            <span className='text-yellow-600 dark:text-yellow-500'>
              {t('profile.action-required-description')}
            </span>
          )
        },
      },
      {
        id: 'actions',
        align: 'right',
        cell: (info) => {
          return (
            <div className='flex gap-2 items-center justify-end'>
              <span className='whitespace-nowrap'>{t('shared.mark-as')}</span>
              <CharacterActions {...info.row.original} />
            </div>
          )
        },
      },
    ],
    [props.characters, userCharacterDetails, loadingAction, loadingMain],
  )

  return (
    <div className='bg-muted rounded-md'>
      {transferringCharacter && (
        <TransferModal
          key={transferringCharacter?.id}
          character={transferringCharacter}
          setOpen={(_value: boolean) => setTransferringCharacter(undefined)}
        />
      )}

      {helpModalOpen && (
        <TroubleshootingModal
          setOpen={(value: boolean) => setHelpModalOpen(value)}
        />
      )}

      <DataTable
        columns={columns}
        data={props.characters}
        includeHeader={true}
        emptyMessage={() => (
          <Trans
            i18nKey='profile.character-table.empty'
            components={{
              modal: (
                <a
                  className='text-primary cursor-pointer'
                  onClick={() => setHelpModalOpen(true)}
                />
              ),
            }}
          />
        )}
        visibilityState={
          props.actionable
            ? {
                main: false,
                itemLevel: false,
                teams: false,
                refreshedAt: false,
              }
            : {
                actionableReason: false,
                actions: false,
              }
        }
      />
    </div>
  )
}

export default CharacterTable
