import React from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import PremiumBenefits from '../pages/GuildDashboard/components/PremiumBenefits'
import { SubscriptionTier } from 'app/frontend/types/enums'

interface Props {
  tierName: SubscriptionTier
}

const PremiumBenefitsPreviewModal = ({
  tierName,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className={'max-w-5xl'}>
        <DialogHeader>
          <DialogTitle>Boost preview</DialogTitle>
        </DialogHeader>

        <PremiumBenefits guild={{}} benefits={[]} previewingTier={tierName} />

        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default PremiumBenefitsPreviewModal
