import React, { useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import PremiumCard from './PremiumCard'
import { useSubscription } from '../helpers'
import { usePageInfo } from '../config/queries'
import { useTranslation } from 'react-i18next'
import RefreshPatreonButton from './RefreshPatreonButton'

const PremiumModal = ({ children }: React.PropsWithChildren) => {
  const [checkoutStarted, setCheckoutStarted] = useState(false)
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()

  const { subscription, legacySubscription, subscriptionActive } =
    useSubscription(user)

  const patreonAccount = user?.linkedAccounts.find(
    (account) => account.displayType === 'Patreon',
  )

  return (
    <Dialog
      // Reset the checkout state with a delay, since the dialog closes with an animation
      onOpenChange={() => setTimeout(() => setCheckoutStarted(false), 500)}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className={
          subscriptionActive && legacySubscription?.details.pledge_tier
            ? 'max-w-7xl'
            : 'max-w-6xl'
        }
      >
        <DialogHeader>
          <DialogTitle>Premium</DialogTitle>
        </DialogHeader>

        {checkoutStarted ? (
          <div className='bg-muted p-8 rounded-md text-center'>
            <p>{t('premium.checkout-instructions')}</p>
            <div className='flex gap-2 justify-center mt-6'>
              <Button
                variant='outline'
                onClick={() => setCheckoutStarted(false)}
              >
                {t('premium.choose-different-plan')}
              </Button>
              {patreonAccount ? (
                <RefreshPatreonButton
                  label={t('shared.sync')}
                  variant='default'
                />
              ) : (
                <a href='/auth/patreon'>
                  <Button>{t('shared.sync')}</Button>
                </a>
              )}
            </div>
          </div>
        ) : (
          <div className='text-sm'>
            <p className='whitespace-pre-line mb-6'>{t('premium.intro')}</p>

            <div className='flex gap-2 justify-between'>
              {subscriptionActive && legacySubscription?.details.pledge_tier ? (
                <PremiumCard
                  tierName={legacySubscription.details.pledge_tier}
                  active={true}
                  setClicked={setCheckoutStarted}
                />
              ) : null}

              <PremiumCard
                tierName='Rare'
                active={subscription?.tierName === 'Rare'}
                setClicked={setCheckoutStarted}
              />
              <PremiumCard
                tierName='Epic'
                active={subscription?.tierName === 'Epic'}
                setClicked={setCheckoutStarted}
              />
              <PremiumCard
                tierName='Legendary'
                active={subscription?.tierName === 'Legendary'}
                setClicked={setCheckoutStarted}
              />
            </div>
          </div>
        )}

        <DialogFooter>
          {patreonAccount ? (
            <RefreshPatreonButton />
          ) : !checkoutStarted ? (
            <a href='/auth/patreon'>
              <Button variant='outline'>Connect Patreon</Button>
            </a>
          ) : null}

          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default PremiumModal
