import React, { useMemo } from 'react'
import { useGuildMembers } from 'app/frontend/base/config/queries'
import { DataTable } from '@/components/ui/data-table'
import { ColumnDef } from '@tanstack/react-table'
import {
  characterClass,
  name,
  realm,
  level,
  itemLevel,
  teams,
  refreshedAt,
  rankInGuild,
} from 'app/frontend/base/config/column-definitions/character'
import { useTranslation } from 'react-i18next'
import {
  getErrorDetails,
  useLogo,
  useRefreshData,
} from 'app/frontend/base/helpers'
import { PulseLoader } from 'react-spinners'
import { updateGuild } from 'app/frontend/base/api/guild'
import { Button } from '@/components/ui/button'

import Timestamp from 'app/frontend/base/components/Timestamp'
import { useGuild } from 'app/frontend/base/config/context/guild'

const GuildMembers = () => {
  const { guild } = useGuild()
  const { t } = useTranslation()
  const {
    data: members,
    isLoading,
    isLoadingError,
    error,
    refetch,
  } = useGuildMembers(guild.id)

  const { Logo } = useLogo()

  const { refreshing, refreshData } = useRefreshData({
    retriever: updateGuild,
    id: guild.id,
    successKey: 'dashboard.guild.guild-update-success',
    callback: refetch,
    queryToInvalidate: ['guild', { id: guild.id }],
  })

  const columns: ColumnDef<wowaudit.Character>[] = useMemo(
    () => [
      name,
      characterClass,
      realm,
      level,
      itemLevel(),
      teams(t),
      rankInGuild,
      refreshedAt(t, 'guild'),
    ],
    [],
  )

  return (
    <div>
      <h2 className='font-bold text-2xl mb-4 flex justify-between'>
        <span>
          Guild members {guild?.guildMembers ? `(${guild.guildMembers})` : ''}
        </span>

        <div className='text-sm flex flex-col gap-2 items-end font-normal'>
          <Button
            variant='outline'
            size='sm'
            loading={refreshing}
            spinner='blizzard'
            className='flex items-center gap-3 min-w-[9.5rem]'
            disabled={!guild?.id}
            onClick={refreshData}
          >
            <Logo className='w-6 h-6' />
            {t('shared.update-now')}
          </Button>

          <div className='flex gap-1 text-xs text-right'>
            <span>{t('shared.last-updated')}</span>
            <Timestamp
              time={guild?.characterDataRefreshedAt}
              colorNever={false}
            />
          </div>
        </div>
      </h2>

      <div className='bg-muted rounded-md'>
        <DataTable
          columns={columns}
          data={members || []}
          includeHeader={true}
          pagination={50}
          filterType='includesString'
          sortingState={[{ id: 'rankInGuild', desc: false }]}
          emptyMessage={() => {
            if (isLoading)
              return (
                <div className='my-8'>
                  <PulseLoader
                    loading={true}
                    speedMultiplier={0.65}
                    size={15}
                    color='hsl(var(--primary))'
                  />
                </div>
              )
            if (isLoadingError) {
              const { title, description } = getErrorDetails(error, t)
              return (
                <div className='my-8'>
                  <h3 className='text-xl mb-4'>{title}</h3>
                  <p>{description}</p>
                </div>
              )
            } else {
              return <div className='my-8'>No guild members found.</div>
            }
          }}
        />
      </div>
    </div>
  )
}

export default GuildMembers
