import React from 'react'
import { mediaUrl } from '../helpers'

interface Props {
  character?: wowaudit.Character
  className?: string
}

const CharacterAvatar = ({ character, className }: Props) => {
  return (
    <img
      className={`rounded-full ${className}`}
      src={mediaUrl(character, 'avatar')}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = `/images/bnet-temp.png`
      }}
    />
  )
}

export default CharacterAvatar
