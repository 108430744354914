import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from 'react-i18next'
dayjs.extend(RelativeTime)

interface Props {
  time?: string
  colorNever?: boolean
  className?: string
}

const Timestamp = ({ time, colorNever, className }: Props) => {
  const { t } = useTranslation()
  const [timestampKey, setTimestampKey] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestampKey((prev) => prev + 1)
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  if (time) {
    return (
      <span key={timestampKey} className={className}>
        <strong
          className={`${
            dayjs(time).isBefore(dayjs().subtract(1, 'day'))
              ? `text-yellow-600 dark:text-yellow-500`
              : 'text-primary'
          }`}
        >
          {dayjs(time).fromNow()}
        </strong>
      </span>
    )
  } else {
    return (
      <strong
        key={timestampKey}
        className={`${className} ${
          colorNever ? 'text-red-500' : 'font-normal italic opacity-70'
        }`}
      >
        {t('shared.never').toLowerCase()}
      </strong>
    )
  }
}

export default Timestamp
