import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { groupBy } from 'lodash'
import { Check, ChevronsUpDown, Star, X } from 'lucide-react'
import React, { useMemo, useState } from 'react'

interface Props {
  setValue: (value: string | undefined) => void
  value?: string
  options: {
    label: string
    value: string
    category?: string
    premium?: boolean
  }[]
  isSearchable?: boolean
  isClearable?: boolean
  inModal?: boolean
  className?: string
  minimumSearchLength?: number
  separateCategories?: boolean
}

const Select = ({
  setValue,
  value,
  options,
  className,
  isSearchable,
  isClearable,
  inModal = false,
  minimumSearchLength = 0,
  separateCategories = false,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [term, setTerm] = useState('')

  const selectedOption = options?.find((o) => o.value === value)

  const groupedOptions = useMemo(
    () => groupBy(options || [], 'category'),
    [options],
  )

  return (
    <div className='w-full'>
      <Popover open={open} onOpenChange={setOpen} modal={inModal}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className={`w-full justify-between bg-background hover:bg-background border border-input ${className}`}
          >
            <span
              className={`${value ? '' : 'text-muted-foreground'} font-normal`}
            >
              <span className='flex items-center gap-2'>
                {!selectedOption?.category ? null : (
                  <Badge variant='outline'>{selectedOption.category}</Badge>
                )}
                <span>{selectedOption?.label ?? 'Select option'}</span>
              </span>
            </span>
            {value && isClearable ? (
              <X
                className='ml-2 mr-[-0.3rem] opacity-50 hover:opacity-100 transition-opacity p-1'
                onClick={(e) => {
                  e.preventDefault()
                  setValue(undefined)
                }}
              />
            ) : (
              <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='p-1 w-[--radix-popover-trigger-width]'>
          <Command
            filter={(value, search) => {
              if (value.toLowerCase().includes(search.toLowerCase())) return 1
              return 0
            }}
          >
            {isSearchable ? (
              <CommandInput
                placeholder={'Search'}
                value={term}
                onValueChange={setTerm}
              />
            ) : null}

            <CommandList className='h-full'>
              {term.length >= minimumSearchLength && (
                <CommandEmpty>No results found.</CommandEmpty>
              )}
              {Object.entries(groupedOptions).map(([category, options]) => {
                const optionElements = options.map((option) => (
                  <CommandItem
                    key={option.value as string}
                    value={option.value}
                    onSelect={() => {
                      setValue(option.value)
                      setOpen(false)
                    }}
                  >
                    {!category || separateCategories ? null : (
                      <Badge variant='outline'>{option.category}</Badge>
                    )}

                    <span className='flex-grow'>{option.label}</span>

                    <Check
                      size={16}
                      className={cn(
                        'ml-auto',
                        option.value === value ? 'opacity-100' : 'opacity-0',
                      )}
                    />

                    {option.premium ? (
                      <Star
                        width={12}
                        className='text-subscription-gold mr-1'
                      />
                    ) : null}
                  </CommandItem>
                ))

                return (
                  <React.Fragment key={category}>
                    {separateCategories && category !== 'undefined' ? (
                      <CommandGroup heading={category}>
                        {optionElements}
                      </CommandGroup>
                    ) : (
                      optionElements
                    )}
                  </React.Fragment>
                )
              })}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default Select
