import {
  Command,
  CommandDialog,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { getSearchResults } from '../api/search'
import { PulseLoader } from 'react-spinners'
import CharacterClassIcon from './CharacterClassIcon'
import RealmSelect from './RealmSelect'
import { Link } from 'react-router-dom'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import FactionIcon from './FactionIcon'
import { displayErrorToast } from '../helpers'

interface Props {
  placeholder: string
  type: 'inline' | 'dialog'
  setOpen?: (value: boolean) => void
}

type SearchResult = wowaudit.Character | wowaudit.Guild | wowaudit.Community

const SearchBar = ({ placeholder, type, setOpen }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const [searchingBlizzard, setSearchingBlizzard] = useState<boolean>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedRealm, setSelectedRealm] = useState<wowaudit.Realm>()
  const [results, setResults] = useState<SearchResult[]>([])

  const CommandComponent = type === 'inline' ? Command : CommandDialog
  const commandProps =
    type === 'inline'
      ? {}
      : {
          open: true,
          onOpenChange: setOpen,
          maxWidth: '40rem',
          centered: false,
        }

  useDebounce(
    () => {
      if (searchTerm.length > 1) {
        fetchResults('internal')
      } else {
        setLoading(false)
      }
    },
    200,
    [searchTerm, selectedRealm],
  )

  const handleInputChange = (term: string) => {
    if (term.length < 2) {
      setResults([])
      setInitiallyLoaded(false)
    } else {
      setLoading(true)
    }
  }

  // TODO: can we use react-query in a nice way for this (more) complex use case?
  const fetchResults = (type: 'internal' | 'external') => {
    getSearchResults(searchTerm, type, selectedRealm)
      .then((data) => {
        setResults(data)
        type === 'internal' ? setLoading(false) : setSearchingBlizzard(false)
        setInitiallyLoaded(true)
      })
      .catch((query) => {
        displayErrorToast(query, t)
        type === 'internal' ? setLoading(false) : setSearchingBlizzard(false)
      })
      .then(() => {
        if (type === 'internal') setSearchingBlizzard(undefined)
      })
  }

  return (
    <CommandComponent
      {...commandProps}
      shouldFilter={false}
      filter={() => 1}
      className='bg-transparent'
    >
      <div
        className={`flex items-center justify-between gap-0.5 ${
          type === 'dialog' ? 'flex-row-reverse' : ''
        } bg-background border-b-2`}
      >
        <div
          className={`relative bg-background flex-grow ${
            type === 'dialog' ? 'border-l-2' : ''
          }`}
        >
          <CommandInput
            onValueChange={(value) => {
              setSearchTerm(value)
              handleInputChange(value)
            }}
            value={searchTerm}
            placeholder={placeholder}
            className='h-[2.35rem]'
          />
          <div
            className={`absolute ${
              type === 'inline' ? 'right-[0.8rem]' : 'right-[2.4rem]'
            } ${
              type === 'inline' ? 'top-[0.5rem]' : 'top-[0.8rem]'
            } opacity-30`}
          >
            <PulseLoader
              loading={loading}
              speedMultiplier={0.65}
              size={10}
              color='hsl(var(--primary))'
            />
          </div>
        </div>

        <RealmSelect
          realm={selectedRealm}
          setRealm={(realm) => {
            setSelectedRealm(realm)
            handleInputChange(searchTerm)
          }}
          inModal={type === 'dialog'}
          isClearable={true}
          className={`rounded-l-none border-0 ${
            type === 'dialog' ? '' : 'border-l-2'
          }`}
        />
      </div>

      <CommandList className='bg-background'>
        {searchTerm.length > 1 && initiallyLoaded && !results.length && (
          <div>
            <div className='py-6 text-center text-sm flex flex-col items-center gap-4'>
              <span>
                {t(
                  searchingBlizzard === false
                    ? 'header.no-search-results-blizzard'
                    : 'header.no-search-results',
                )}
              </span>

              <Tooltip delayDuration={150}>
                {selectedRealm ? (
                  <Button
                    variant='outline'
                    className={`inline ${
                      searchingBlizzard === false ? 'invisible' : ''
                    }`}
                    loading={searchingBlizzard}
                    spinner='blizzard'
                    onClick={() => {
                      setSearchingBlizzard(true)
                      fetchResults('external')
                    }}
                  >
                    {t(`header.search-official-api`)}
                  </Button>
                ) : (
                  <TooltipTrigger
                    className={`${buttonVariants({
                      variant: 'outline',
                    })} cursor-not-allowed opacity-50`}
                  >
                    {t(`header.search-official-api`)}
                  </TooltipTrigger>
                )}

                <TooltipContent>
                  <p>{t('header.choose-realm-first')}</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}
        {results.map((result, i) => {
          // Do we want to pass this data from the backend explicitly instead?
          const resultType =
            'characterClass' in result
              ? 'Character'
              : 'faction' in result
              ? 'Guild'
              : 'Community'

          return (
            <CommandItem key={i} value={result.name}>
              <Link
                className='w-full'
                to={result.path}
                state={{ [resultType.toLowerCase()]: result }}
                onClick={() =>
                  type === 'dialog' && setOpen ? setOpen(false) : null
                }
              >
                <div className='flex w-full px-2 py-0.5 items-center justify-between'>
                  <div className='flex items-center gap-3'>
                    {'characterClass' in result ? (
                      <CharacterClassIcon
                        characterClass={result.characterClass}
                      />
                    ) : (
                      <FactionIcon
                        faction={
                          'faction' in result ? result.faction : undefined
                        }
                      />
                    )}

                    <span className='w-[14rem]'>{result.name}</span>
                    {'realm' in result ? (
                      <span>
                        {result.realm.name} ({result.realm.region})
                      </span>
                    ) : null}
                  </div>

                  <span className='text-muted-foreground'>{resultType}</span>
                </div>
              </Link>
            </CommandItem>
          )
        })}
      </CommandList>
    </CommandComponent>
  )
}

export default SearchBar
