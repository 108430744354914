import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

export const getRequest = async (url: string) => {
  return await axios.get(url).then((response) => response.data)
}

export const postRequest = async (url: string, data: any) => {
  return await axios.post(url, data).then((response) => response.data)
}

export const putRequest = async (url: string, data: any) => {
  return await axios.put(url, data).then((response) => response.data)
}

export const deleteRequest = async (url: string) => {
  return await axios.delete(url).then((response) => response.data)
}
