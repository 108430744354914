export const itemTypes = [
  'head',
  'neck',
  'shoulder',
  'back',
  'chest',
  'wrist',
  'hands',
  'waist',
  'legs',
  'feet',
  'finger_1',
  'finger_2',
  'trinket_1',
  'trinket_2',
  'main_hand',
  'off_hand',
  'ranged',
] as const

export const itemTypeLabels = {
  head: 'Helm',
  neck: 'Neck',
  shoulder: 'Shoulder',
  back: 'Cloak',
  chest: 'Chest',
  wrist: 'Wrist',
  hands: 'Gloves',
  waist: 'Belt',
  legs: 'Legs',
  feet: 'Boots',
  finger_1: 'Ring',
  finger_2: 'Ring',
  trinket_1: 'Trinket',
  trinket_2: 'Trinket',
  main_hand: 'Main hand',
  off_hand: 'Off hand',
  ranged: 'Ranged weapon',
}

type ItemType = typeof itemTypes[number]
export default ItemType
