export const upgradeTracks = [
  'Myth',
  'Hero',
  'Champion',
  'Veteran',
  'Adventurer',
  'Explorer',
  'None',
] as const

type UpgradeTrack = typeof upgradeTracks[number]
export default UpgradeTrack
