import React from 'react'

interface Props {
  points: number
  pointSpentPerEntity: number[]
  label: 'points' | ''
}

const UserBenefitsBar = ({ points, pointSpentPerEntity, label }: Props) => {
  const totalSpent = pointSpentPerEntity.reduce((acc, val) => acc + val, 0)

  return (
    <div className='flex gap-4 items-center'>
      <div className='my-4 w-full h-8 flex bg-accent rounded-xl gap-0.5'>
        {pointSpentPerEntity.map((spent, index) => (
          <div
            key={index}
            style={{ width: `${(spent / points) * 100.0}%` }}
            className={`bg-primary ${index === 0 ? 'rounded-l-xl' : ''} ${
              index === pointSpentPerEntity.length - 1 ? 'rounded-r-xl' : ''
            }`}
          ></div>
        ))}
      </div>
      <span className='whitespace-nowrap'>
        {totalSpent} / {points} {label} granted
      </span>
    </div>
  )
}

export default UserBenefitsBar
