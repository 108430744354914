import React from 'react'
import { Link } from 'react-router-dom'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'

interface Props {
  setOpen: (value: boolean) => void
}

const TroubleshootingModal = ({ setOpen }: Props) => {
  return (
    <Dialog open={true} onOpenChange={setOpen}>
      <DialogContent className='max-w-2xl'>
        <DialogHeader>
          <DialogTitle>Troubleshooting</DialogTitle>
        </DialogHeader>

        <div className='text-sm'>
          {window.location.pathname === '/profile' ? (
            'You can try to update your characters on this page'
          ) : (
            <React.Fragment>
              You can try to update your characters in your{' '}
              <Link to='/profile'>profile</Link>
            </React.Fragment>
          )}{' '}
          (ensure that your profile shows the correct Blizzard account). As a
          first step, confirm with your guild administrator that the access
          control allows your account to have access. Keep in mind that if you
          recently server transferred it can take up to a few days before your
          character is visible. If you&apos;ve verified that, please ensure that
          in your{' '}
          <a
            href='https://account.battle.net/privacy'
            target='blizzard_settings'
          >
            Blizzard settings
          </a>{' '}
          you have enabled this option:
          <img
            className='my-4'
            src='https://data.wowaudit.com/img/game-data.png'
          />
          If that is the case, also ensure that on the{' '}
          <a
            href='https://account.battle.net/connections'
            target='blizzard_settings'
          >
            Connections page
          </a>{' '}
          your wowaudit authorization looks exactly like this:
          <img
            className='my-4'
            src='https://data.wowaudit.com/img/profile.png'
          />
          If you&apos;ve made any changes, remember to explicitly refresh your
          list of characters in your profile (simply refreshing the page is not
          enough). If nothing works, please reach out on{' '}
          <a
            className='text-primary'
            href='https://discord.gg/86eUAFz'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            Discord
          </a>{' '}
          and you will be assisted.
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default TroubleshootingModal
