import React, { useEffect } from 'react'
import { Command, CommandGroup, CommandItem, CommandList } from './command'
import { Badge } from './badge'
import { Command as CommandPrimitive } from 'cmdk'
import { X } from 'lucide-react'

interface Props<T> {
  options: { label: string; value: T }[]
  defaultValue?: { label: string; value: T }[]
  onChange: (value: { label: string; value: T }[]) => void
}

export function MultiSelect<T>({ options, defaultValue, onChange }: Props<T>) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<{ label: string; value: T }[]>(
    defaultValue ?? [],
  )
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const handleUnselect = React.useCallback(
    (option: { label: string; value: T }) => {
      setSelected((prev) => prev.filter((s) => s.value !== option.value))
    },
    [],
  )

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            setSelected((prev) => {
              const newSelected = [...prev]
              newSelected.pop()
              return newSelected
            })
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    [],
  )

  const selectables = options.filter(
    (option) => !selected.map((o) => o.value).includes(option.value),
  )

  return (
    <Command
      onKeyDown={handleKeyDown}
      className='overflow-visible bg-transparent'
    >
      <div className='group bg-background rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2'>
        <div className='flex flex-wrap gap-1'>
          {selected.map((option) => {
            return (
              <Badge key={option.value as string} variant='secondary'>
                {option.label}
                <button
                  className='ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className='h-3 w-3 text-muted-foreground hover:text-foreground' />
                </button>
              </Badge>
            )
          })}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={selected.length === 0 ? 'Select options...' : ''}
            className='ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground'
          />
        </div>
      </div>
      <div className='relative'>
        <CommandList>
          {open && selectables.length > 0 ? (
            <div className='absolute top-2 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in'>
              <CommandGroup className='h-full overflow-auto'>
                {selectables.map((option) => {
                  return (
                    <CommandItem
                      key={option.value as string}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onSelect={() => {
                        setInputValue('')
                        setSelected((prev) => [...prev, option])
                      }}
                      className={'cursor-pointer'}
                    >
                      {option.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  )
}
