import { getRequest } from '.'

export const getPendingFetchCharactersJob = async () => {
  return await getPendingJob('FetchUserCharactersJob')
}

export const getPendingJobById = async (jobId: string) => {
  return await getPendingJob(jobId)
}

const getPendingJob = async (
  job: string,
  referenceId?: number,
): Promise<wowaudit.JobStatus> => {
  return await getRequest(
    `/api/jobs/${job}${referenceId ? `?reference_id=${referenceId}` : ''}`,
  )
}
