import { CharacterClass } from 'app/frontend/types/enums'
import { characterClasses } from 'app/frontend/types/enums/CharacterClass'
import React from 'react'

interface Props {
  characterClass?: CharacterClass
}

const iconStyles = 'w-6 h-6 border-2 border-[#666] rounded-md'

const CharacterClassIcon = ({ characterClass }: Props) => {
  if (characterClass && characterClasses.includes(characterClass)) {
    return (
      <img
        className={iconStyles}
        src={`/images/classes/${characterClass.toLowerCase()}.jpeg`}
      />
    )
  } else {
    return <img className={iconStyles} src={`/images/classes/unknown.png`} />
  }
}

export default CharacterClassIcon
