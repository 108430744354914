import { useTeam } from 'app/frontend/base/config/context/team'
import React from 'react'

const TeamEvents = () => {
  const { team } = useTeam()

  return <div>TeamEvents! {team.id}</div>
}

export default TeamEvents
