import React, { useState } from 'react'
import { regions } from 'app/frontend/types/enums/Region'
import { updateActiveRegions } from 'app/frontend/base/api/user'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { useTranslation } from 'react-i18next'
import { usePageInfo } from 'app/frontend/base/config/queries'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  disabled?: boolean
  updateCharacters: () => void
}

const ActiveRegions = ({ disabled, updateCharacters }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [enabling, setEnabling] = useState<string>()
  const [disabling, setDisabling] = useState<string>()
  const {
    data: { user },
  } = usePageInfo()

  return (
    <div className='flex justify-end'>
      <div className='inline-flex text-xs border border-muted'>
        {regions.map((region, i) => {
          const active = user?.regions
            .map((r) => r.toUpperCase())
            .includes(region.toUpperCase())

          return (
            <div
              key={region}
              className={`py-1 px-2 w-[2.3rem] text-center cursor-pointer transition-opacity ${
                i === 0 ? '' : 'border-l border-muted'
              } ${
                enabling === region || (disabling !== region && active)
                  ? 'text-primary font-bold bg-muted'
                  : 'opacity-50 hover:opacity-100'
              } ${
                disabled || enabling || disabling ? 'pointer-events-none' : ''
              }`}
              onClick={() => {
                active ? setDisabling(region) : setEnabling(region)
                updateActiveRegions({
                  regions: (user?.regions || [])
                    .filter((r) => r.toUpperCase() !== region.toUpperCase())
                    .concat(active ? [] : [region.toUpperCase()]),
                })
                  .then((response) => {
                    queryClient.setQueryData(['pageInfo'], response)
                    if (enabling) updateCharacters()
                  })
                  .catch((err) => displayErrorToast(err, t))
                  .then(() => {
                    setEnabling(undefined)
                    setDisabling(undefined)
                  })
              }}
            >
              {region}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActiveRegions
