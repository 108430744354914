import { CrestType, EquippedItem, UpgradeTrack } from 'app/frontend/types/enums'
import { upgradeTracks } from '../../../../types/enums/UpgradeTrack'
import { crestTypes } from '../../../../types/enums/CrestType'
import React, { useMemo } from 'react'
import ItemIcon from '../../../components/ItemIcon'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { usePageInfo } from 'app/frontend/base/config/queries'

interface Props {
  character?: wowaudit.Character
}

const colorByCrestType = {
  Gilded: 'legendary',
  Runed: 'epic',
  Carved: 'rare',
  Weathered: 'uncommon',
}

const UpgradesSummary = ({ character }: Props) => {
  const {
    data: { currentPeriod, seasonData },
  } = usePageInfo()

  const liStyles = 'whitespace-nowrap bg-accent my-1 py-1 px-2 rounded-md'

  const upgrades = useMemo(() => {
    if (!character?.details?.gear) return null

    return Object.values(character.details.gear).reduce((acc, item) => {
      if (!item.upgrade_level || item.upgrade_level.track === 'None') return acc

      const track = item.upgrade_level?.track
      if (!acc[track]) acc[track] = []

      const costs = {} as { [key in CrestType]: number }
      const trackIndex = upgradeTracks.indexOf(track)

      for (
        let i = item.upgrade_level.step || 0;
        i < (item.upgrade_level.total || 0);
        i++
      ) {
        // After step 4 the crest of the next difficulty is required
        const crest =
          i >= 4 && trackIndex !== 0
            ? crestTypes[trackIndex - 1]
            : crestTypes[trackIndex]

        if (!crest) continue
        if (!costs[crest]) costs[crest] = 0
        costs[crest] += 15
      }

      acc[track].push({ costs, item })

      return acc
    }, {} as Record<UpgradeTrack, { costs: { [key in CrestType]: number }; item: EquippedItem }[]>)
  }, [character?.details])

  return (
    <ul>
      {upgradeTracks.map((track) => {
        if (track === 'None') return null

        const records = upgrades?.[track] || []
        const upgradableAmount = records.reduce(
          (acc, record) =>
            acc +
            (record.item.upgrade_level?.total || 0) -
            (record.item.upgrade_level?.step || 0),
          0,
        )

        if (
          ['Adventurer', 'Explorer'].includes(track) &&
          upgradableAmount === 0
        )
          return null

        const costsByCrest = {} as { [key in CrestType]: number }
        records.forEach((record) => {
          Object.entries(record.costs).forEach(([key, amount]) => {
            const crest = key as CrestType
            if (!costsByCrest[crest]) costsByCrest[crest] = 0
            costsByCrest[crest] += amount
          })
        })

        return (
          <li key={track} className={liStyles}>
            <div>
              <div className='flex justify-between'>
                <span>
                  <strong>{track}</strong> track
                </span>
                <span>
                  <strong>{records.length}</strong> item
                  {records.length === 1 ? '' : 's'}
                </span>
              </div>

              {records.length > 0 && (
                <div className='flex justify-between mt-1 gap-2'>
                  <span className='italic'>
                    <strong>{upgradableAmount}</strong> upgrades left
                  </span>

                  {upgradableAmount > 0 && (
                    <span className='flex gap-2'>
                      {Object.keys(costsByCrest).map((crest) => {
                        const crestType = seasonData.crests.find(
                          (c) => c.name === crest,
                        )
                        return (
                          <span key={crest} className='flex gap-1 items-center'>
                            <strong
                              className={`text-quality-${
                                colorByCrestType[crest as CrestType]
                              }`}
                            >
                              {costsByCrest[crest as CrestType]}×
                            </strong>
                            <Tooltip delayDuration={150}>
                              <TooltipTrigger>
                                <ItemIcon id={crestType?.icon_item_id} />
                              </TooltipTrigger>
                              <TooltipContent>{crestType?.name}</TooltipContent>
                            </Tooltip>
                          </span>
                        )
                      })}
                    </span>
                  )}
                </div>
              )}
            </div>
          </li>
        )
      })}

      {character?.refreshedAt ? (
        <React.Fragment>
          <li className='mt-2 mb-1 pb-1 block border-b border-accent font-bold'>
            Crests earnable
          </li>

          <li className='flex gap-1'>
            {seasonData.crests.slice(0, 3).map((crestType) => {
              const currentCap =
                Math.max(
                  0,
                  currentPeriod[character?.realm.region] -
                    crestType.first_period,
                ) * crestType.weekly_increase

              return (
                <div key={crestType.name} className={`${liStyles} grow`}>
                  <div className='flex items-center justify-center gap-2 mb-1'>
                    <Tooltip delayDuration={150}>
                      <TooltipTrigger>
                        <ItemIcon id={crestType.icon_item_id} />
                      </TooltipTrigger>
                      <TooltipContent>{crestType.name}</TooltipContent>
                    </Tooltip>
                    <strong
                      className={`text-quality-${
                        colorByCrestType[crestType.name]
                      }`}
                    >
                      {Math.max(
                        0,
                        currentCap -
                          (character.details?.crestsEarned?.[crestType.name] ||
                            0),
                      )}
                    </strong>
                  </div>

                  <div className='text-xs opacity-70 text-center'>
                    {character.details?.crestsEarned?.[crestType.name]} /{' '}
                    {currentCap}
                  </div>
                </div>
              )
            })}
          </li>
        </React.Fragment>
      ) : null}
    </ul>
  )
}

export default UpgradesSummary
