import { useGuild } from 'app/frontend/base/config/context/guild'
import React from 'react'

interface Props {
  scope: 'guild' | 'currentTeam'
}

const GuildNews = ({ scope }: Props) => {
  const { guild } = useGuild()

  return (
    <div>
      GuildNews! {scope} {guild.id}
    </div>
  )
}

export default GuildNews
