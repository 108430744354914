import React from 'react'
import PremiumBenefitCategory, {
  PointDistribution,
} from 'app/frontend/types/enums/PremiumBenefitCategory'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import { Minus, Plus } from 'lucide-react'
import PremiumBenefitBar from './PremiumBenefitBar'

interface Props {
  category: PremiumBenefitCategory
  previousPoints: number
  pointsSpent: PointDistribution
  setPointsSpent: (value: number) => void
  className: string
}

const limits = {
  refresh_factor: { min: 1, max: 30, start: 0, step: 1 },
  team_limit: { min: 1, max: 10, start: 0, step: 1 },
  character_limit: { min: 30, max: 100, start: 20, step: 10 },
}

const PremiumBenefitBlock = ({
  category,
  pointsSpent,
  previousPoints,
  setPointsSpent,
  className,
}: Props) => {
  const { t } = useTranslation()

  const currentLevel = Math.min(
    limits[category].max,
    limits[category].min +
      (pointsSpent.otherOrLegacy + pointsSpent.own) * limits[category].step,
  )

  const steps =
    (limits[category].max - limits[category].start - limits[category].step) /
    limits[category].step

  const updatePoints = (value: number) => {
    if (value < 0) return

    setPointsSpent(value)
  }

  return (
    <div className={`${className} rounded-md py-2 px-3`}>
      <div className='flex items-center justify-between gap-2'>
        <div>
          <h2 className='font-bold mb-1'>
            <span>{t(`premium.benefits.${category}.label`)}</span>
          </h2>
          <p className='text-sm'>
            {t(`premium.benefits.${category}.description`)}
          </p>
        </div>
        <span className='text-lg font-bold bg-accent py-2 px-3 rounded-md w-14 text-center'>
          {category === 'refresh_factor' ? '×' : ''}
          {currentLevel}
        </span>
      </div>

      <div className='flex gap-2 items-center'>
        <Button
          variant='outline'
          size='sm'
          className='rounded-lg h-8'
          disabled={pointsSpent.own === 0}
          onClick={() => updatePoints(pointsSpent.own - 1)}
        >
          <Minus size={16} />
        </Button>

        <PremiumBenefitBar
          steps={steps}
          pointsSpent={pointsSpent}
          previousPoints={previousPoints}
          onClick={updatePoints}
        />

        <Button
          size='sm'
          className='rounded-lg h-8'
          disabled={pointsSpent.own + pointsSpent.otherOrLegacy >= steps}
          onClick={() => updatePoints(pointsSpent.own + 1)}
        >
          <Plus size={16} />
        </Button>
      </div>
    </div>
  )
}

export default PremiumBenefitBlock
