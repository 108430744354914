import React from 'react'
import { Main } from '../../components'

const ApiOverview = () => {
  return (
    <Main>
      <div>ApiOverview! Explain scoped to teams, team selector on page.</div>
    </Main>
  )
}

export default ApiOverview
