import { Beneficiary, PremiumBenefitCategory } from 'app/frontend/types/enums'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

export const getPageInfo = async (): Promise<wowaudit.PageInfo> => {
  return await getRequest(`/api/user/page_info`)
}

export const getPersonalCharactersDetails = async (): Promise<
  wowaudit.Character[]
> => {
  return await getRequest(`/api/user/characters`)
}

export const refreshPersonalCharacters = async (): Promise<{
  job_id: string
}> => {
  return await postRequest(`/api/user/characters`, {})
}

export const updatePersonalCharacter = async (
  characterId: number,
  changes: Partial<wowaudit.Character>,
): Promise<void> => {
  return await putRequest(`/api/user/characters/${characterId}`, {
    character: changes,
  })
}

export const unlinkAccount = async (id: number): Promise<void> => {
  return deleteRequest(`/api/user/linked_accounts/${id}`)
}

export const updateActiveRegions = async (
  data: Partial<wowaudit.User>,
): Promise<wowaudit.PageInfo> => {
  return await putRequest(`/api/user/regions`, { user: data })
}

export const revokeLegacyBenefits = async (
  linkedAccountId: number,
  beneficiaryId: number,
  beneficiaryType: Beneficiary,
): Promise<void> => {
  return await deleteRequest(
    `/api/user/linked_accounts/${linkedAccountId}/legacy_benefits/${beneficiaryId}?type=${beneficiaryType}`,
  )
}

export const grantLegacyBenefits = async (
  linkedAccountId: number,
  beneficiaryId: number,
  beneficiaryType: Beneficiary,
): Promise<void> => {
  return await putRequest(
    `/api/user/linked_accounts/${linkedAccountId}/legacy_benefits/${beneficiaryId}`,
    { type: beneficiaryType },
  )
}

export const revokeSubscriptionBenefits = async (
  beneficiaryId: number,
  beneficiaryType: Beneficiary,
): Promise<void> => {
  return await deleteRequest(
    `/api/user/premium_benefits/${beneficiaryId}?type=${beneficiaryType}`,
  )
}

export const savePremiumBenefits = async (
  beneficiaryId: number,
  beneficiaryType: Beneficiary,
  points: { [key in PremiumBenefitCategory]: number },
): Promise<void> => {
  return await putRequest(`/api/user/premium_benefits/${beneficiaryId}`, {
    points,
    type: beneficiaryType,
  })
}

export const refreshLinkedAccountData = async (
  id: number,
): Promise<{ job_id: string }> => {
  return await putRequest(`/api/user/linked_accounts/${id}`, {})
}
