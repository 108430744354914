import { useTeam } from 'app/frontend/base/config/context/team'
import React from 'react'

const TeamRoster = () => {
  const { team } = useTeam()

  return <div>TeamRoster! {team.id}</div>
}

export default TeamRoster
