import { useQuery } from '@tanstack/react-query'

import { getPageInfo, getPersonalCharactersDetails } from '../api/user'
import { getRealms } from '../api/realm'
import { getGuildDetails, getGuildMembers } from '../api/guild'
import { getTeamDetails } from '../api/team'
import { getOptions } from '../api/notification'

export const usePageInfo = () => {
  return useQuery({
    queryKey: ['pageInfo'],
    queryFn: getPageInfo,
    staleTime: 1000 * 60 * 15,
    initialData: window.page_info,
  })
}

export const useRealms = () => {
  return useQuery({
    queryKey: ['realms'],
    queryFn: getRealms,
    staleTime: 1000 * 60 * 60,
  })
}

export const useNotificationTemplateOptions = () => {
  return useQuery({
    queryKey: ['notificationTemplateOptions'],
    queryFn: getOptions,
    staleTime: Infinity,
  })
}

export const useUserCharacterDetails = (enabled: boolean) => {
  return useQuery({
    queryKey: ['userCharacterDetails'],
    queryFn: getPersonalCharactersDetails,
    enabled: enabled,
    staleTime: 1000 * 60 * 5,
  })
}

export const useGuildDetails = (guildId: number | undefined) => {
  return useQuery({
    queryKey: ['guild', { id: guildId }],
    queryFn: () => (guildId ? getGuildDetails(guildId) : null),
    enabled: !!guildId,
    staleTime: 1000 * 60 * 60,
  })
}

export const useTeamDetails = (teamId: number | undefined) => {
  return useQuery({
    queryKey: ['team', { id: teamId }],
    queryFn: () => (teamId ? getTeamDetails(teamId) : null),
    enabled: !!teamId,
    staleTime: 1000 * 60 * 60,
  })
}

export const useGuildMembers = (guildId: number | undefined) => {
  return useQuery({
    queryKey: ['guildMembers', { id: guildId }],
    queryFn: () => (guildId ? getGuildMembers(guildId) : null),
    enabled: !!guildId,
    staleTime: 1000 * 60 * 60,
  })
}
