import { useTeam } from 'app/frontend/base/config/context/team'
import React from 'react'

const TeamLoot = () => {
  const { team } = useTeam()

  return <div>TeamLoot! {team.id}</div>
}

export default TeamLoot
