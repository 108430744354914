import React, { useEffect, useMemo, useState } from 'react'
import PremiumBenefitBlock from './PremiumBenefitBlock'
import PremiumBenefitCategory, {
  PointDistribution,
  PointsSpentByCategory,
  premiumBenefitCategories,
} from 'app/frontend/types/enums/PremiumBenefitCategory'
import { LegacyPledgeTier, SubscriptionTier } from 'app/frontend/types/enums'
import { useTranslation } from 'react-i18next'
import { usePageInfo } from '../../../config/queries'
import PremiumBenefitSummary from './PremiumBenefitSummary'
import PremiumBenefitBar from './PremiumBenefitBar'
import { Button } from '@/components/ui/button'
import { savePremiumBenefits } from 'app/frontend/base/api/user'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { toast } from 'sonner'
import { isEqual } from 'lodash'

interface Props {
  guild: Partial<wowaudit.Guild>
  benefits: wowaudit.PremiumBenefit[]
  legacyTier?: LegacyPledgeTier
  previewingTier?: SubscriptionTier
  blockClassName?: string
}

const extraBenefitsMax = 15

export const legacyPoints = {
  Free: { refresh_factor: 0, team_limit: 0, character_limit: 0, total: 0 },
  Bronze: { refresh_factor: 2, team_limit: 1, character_limit: 1, total: 4 },
  Silver: { refresh_factor: 5, team_limit: 4, character_limit: 2, total: 11 },
  Gold: { refresh_factor: 9, team_limit: 6, character_limit: 5, total: 20 },
  Platinum: {
    refresh_factor: 14,
    team_limit: 9,
    character_limit: 7,
    total: 30,
  },
}

const PremiumBenefits = ({
  guild,
  benefits,
  legacyTier,
  previewingTier,
  blockClassName,
}: Props) => {
  const { t, i18n } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()
  const [saving, setSaving] = useState(false)

  const originalPointsSpent = useMemo(() => {
    return premiumBenefitCategories.reduce((acc, category) => {
      const otherOrLegacy =
        benefits
          .filter((b) => b.userId !== user?.id && b.category === category)
          .reduce((acc, b) => acc + b.points, 0) +
        legacyPoints[legacyTier || 'Free'][category]

      const own = benefits
        .filter((b) => b.userId === user?.id && b.category === category)
        .reduce((acc, b) => acc + b.points, 0)

      acc[category] = { otherOrLegacy, own }
      return acc
    }, {} as PointsSpentByCategory)
  }, [benefits, legacyTier])

  // Ideally we refactor this logic to only store the changes in state, as a delta to the original points spent.
  // In that case we don't have to update the state when the props change.
  const [pointsSpent, setPointsSpent] =
    useState<PointsSpentByCategory>(originalPointsSpent)

  const totalPointsSpent = useMemo(() => {
    return premiumBenefitCategories.reduce(
      (acc, category) => {
        return {
          otherOrLegacy:
            acc.otherOrLegacy + pointsSpent[category].otherOrLegacy,
          own: acc.own + pointsSpent[category].own,
        }
      },
      { own: 0, otherOrLegacy: 0 } as PointDistribution,
    )
  }, [pointsSpent])

  // Used to control the transition direction of the bars
  const [previousPoints, setPreviousPoints] = useState(pointsSpent)
  const [previousOwnTotalPoints, setPreviousOwnTotalPoints] = useState(
    totalPointsSpent.own,
  )

  useEffect(() => {
    if (!isEqual(originalPointsSpent, pointsSpent)) {
      setPreviousOwnTotalPoints(totalPointsSpent.own)
      setPreviousPoints(originalPointsSpent)
      setPointsSpent(originalPointsSpent)
    }
  }, [originalPointsSpent])

  const hasChanges = useMemo(() => {
    return premiumBenefitCategories.some((category) => {
      return pointsSpent[category].own !== originalPointsSpent[category].own
    })
  }, [pointsSpent])

  const resetDynamicPoints = () => {
    setPreviousOwnTotalPoints(totalPointsSpent.own)
    setPreviousPoints(pointsSpent)
    setPointsSpent({
      ...pointsSpent,
      ...premiumBenefitCategories.reduce((acc, category) => {
        acc[category] = { ...pointsSpent[category], own: 0 }
        return acc
      }, {} as PointsSpentByCategory),
    })
  }

  const updatePointsSpent = (
    category: PremiumBenefitCategory,
    value: number,
  ) => {
    setPreviousOwnTotalPoints(totalPointsSpent.own)
    setPreviousPoints(pointsSpent)

    setPointsSpent({
      ...pointsSpent,
      [category]: { ...pointsSpent[category], own: value },
    })
  }

  const saveChanges = () => {
    if (guild.id && hasChanges) {
      setSaving(true)

      const points = premiumBenefitCategories.reduce((acc, category) => {
        acc[category] = pointsSpent[category].own
        return acc
      }, {} as { [key in PremiumBenefitCategory]: number })

      savePremiumBenefits(guild.id, 'Guild', points)
        .then(() => {
          setSaving(false)
          toast.success(t('shared.changes-saved'))
        })
        .catch((error) => {
          displayErrorToast(error, t)
          setSaving(false)
        })
    }
  }

  return (
    <div className='my-2' key={guild.legacyPatron?.id || ''}>
      <div className='flex gap-4 w-full'>
        <div className='grow flex flex-col gap-4'>
          <PremiumBenefitSummary
            className={blockClassName ?? 'bg-muted'}
            guild={guild}
            benefits={benefits}
            totalPointsSpent={totalPointsSpent}
            previewingTier={previewingTier}
          >
            <Button
              size='sm'
              variant='outline'
              disabled={totalPointsSpent.own === 0}
              onClick={resetDynamicPoints}
            >
              Remove boosts
            </Button>
            <Button
              size='sm'
              disabled={!hasChanges}
              onClick={saveChanges}
              loading={saving}
              variant='default'
            >
              Save changes
            </Button>
          </PremiumBenefitSummary>

          {premiumBenefitCategories.map((category) => (
            <PremiumBenefitBlock
              key={category}
              className={blockClassName ?? 'bg-muted'}
              category={category}
              previousPoints={previousPoints[category].own}
              pointsSpent={pointsSpent[category]}
              setPointsSpent={(value: number) =>
                updatePointsSpent(category, value)
              }
            />
          ))}
        </div>

        <div
          className={`${
            blockClassName ?? 'bg-muted'
          } py-2 px-3 rounded-md flex flex-col`}
        >
          <h2 className='font-bold'>Extra benefits</h2>

          <p className='text-sm mt-2'>
            Grant boosts to this guild to unlock extra benefits.
          </p>

          <PremiumBenefitBar
            orientation='vertical'
            steps={extraBenefitsMax}
            pointsSpent={totalPointsSpent}
            previousPoints={previousOwnTotalPoints}
            blockContent={(i, active) => {
              const hasBenefit = i18n.exists(
                `premium.recipient-benefits.${i + 1}`,
              )

              return (
                <span className='flex gap-2 relative z-30' key={i}>
                  {hasBenefit ? (
                    <span
                      className={`w-8 text-center transition-colors text-sm font-bold ${
                        active ? 'text-muted' : ''
                      }`}
                    >
                      {i + 1}
                    </span>
                  ) : null}
                  <span
                    className={`${
                      active ? 'text-primary' : 'opacity-70'
                    } text-sm`}
                  >
                    {hasBenefit
                      ? t(`premium.recipient-benefits.${i + 1}`)
                      : '\u00A0'}
                  </span>
                </span>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PremiumBenefits
