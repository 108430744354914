import { createContext, useContext } from 'react'

export interface TeamContextType {
  guild: Partial<wowaudit.Guild>
  team: wowaudit.Team
}

export const TeamContext = createContext<TeamContextType>({} as TeamContextType)

export const useTeam = () => {
  const context = useContext(TeamContext)
  if (context === undefined) {
    throw new Error('useTeam must be used within a TeamProvider')
  }

  return context
}

export default TeamContext
