import React from 'react'
import { z } from 'zod'
import SettingField from '../fields/SettingField'
import FormWrapper from '../FormWrapper'
import { zSetting } from 'app/frontend/base/helpers/forms'

const formSchema = z.object({
  settings: z.object({
    wishlistAutoClear: zSetting(z.coerce.number().min(0).max(720)),
    wishlistDifficulties: zSetting(z.array(z.string())),
    wishlistEditable: zSetting(z.boolean()),
    wishlistLocked: zSetting(z.boolean()),
    wishlistMinimumThreshold: zSetting(z.coerce.number().min(0)),
  }),
})

const LootSection = () => {
  return (
    <FormWrapper formSchema={formSchema}>
      <SettingField name='wishlistDifficulties' />
      <SettingField name='wishlistAutoClear' layout='box' />
      <SettingField name='wishlistMinimumThreshold' layout='box' />
      <SettingField name='wishlistEditable' layout='box' />
      <SettingField name='wishlistLocked' layout='box' />
    </FormWrapper>
  )
}

export default LootSection
