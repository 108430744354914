import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { unlinkAccount } from 'app/frontend/base/api/user'
import { Check, X, Loader2 } from 'lucide-react'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { useTranslation } from 'react-i18next'
import { usePageInfo } from 'app/frontend/base/config/queries'
import { useQueryClient } from '@tanstack/react-query'

const infoByType = {
  Discord: { logo: '/images/discord-logo-blue.svg', authUrl: '/auth/discord' },
  'Battle.net': { logo: '/images/bnet-logo-blue.svg', authUrl: '/auth/bnet' },
  Google: { logo: '/images/google-logo.svg', authUrl: '/auth/google_oauth2' },
  Patreon: { logo: '/images/patreon-logo.svg', authUrl: '/auth/patreon' },
  'Warcraft Logs': {
    logo: 'https://data.wowaudit.com/img/wcl-logo.png',
    authUrl: '/auth/wcl',
  },
}

interface Props {
  type: 'Discord' | 'Battle.net' | 'Google' | 'Patreon' | 'Warcraft Logs'
}

const LinkedAccount = ({ type }: Props) => {
  const [unlinking, setUnlinking] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()

  if (!user) return null

  const connection =
    type === 'Battle.net'
      ? ({
          displayName: user.battletag,
          displayType: 'Battle.net',
          details: {},
        } as wowaudit.LinkedAccount)
      : user.linkedAccounts.find((account) => account.displayType === type)

  const unlink = () => {
    if (!connection || type === 'Battle.net') return
    setUnlinking(true)

    unlinkAccount(connection.id)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['pageInfo'] })
      })
      .catch((err) => displayErrorToast(err, t))
      .then(() => setUnlinking(false))
  }

  return (
    <div className='bg-muted py-3 px-4 my-2 rounded-md flex items-center justify-between gap-2 max-w-[50rem]'>
      <div className='flex items-center gap-4'>
        <img src={infoByType[type].logo} className='w-12' />
        <span className='font-bold text-lg'>{type}</span>
      </div>

      <div className='grow text-right mr-6 text-sm'>
        {connection?.displayName}
      </div>

      <div className='flex gap-4 items-center'>
        {connection ? (
          <Button className={`w-[7rem] pointer-events-none`}>
            <span className='flex gap-2 items-center'>
              <Check size={14} />
              Linked
            </span>
          </Button>
        ) : (
          <a href={infoByType[type].authUrl}>
            <Button variant='outline' className={`w-[7rem]`}>
              Connect
            </Button>
          </a>
        )}

        {unlinking ? (
          <Loader2 size={20} className='animate-spin' />
        ) : (
          <X
            size={20}
            onClick={unlink}
            className={`transition-colors ${
              connection && type !== 'Battle.net'
                ? 'cursor-pointer hover:text-red-500'
                : 'opacity-10 pointer-events-none'
            }`}
          />
        )}
      </div>
    </div>
  )
}

export default LinkedAccount
