import { useTeam } from 'app/frontend/base/config/context/team'
import React from 'react'

const TeamSpreadsheet = () => {
  const { team } = useTeam()

  return <div>TeamSpreadsheet! {team.id}</div>
}

export default TeamSpreadsheet
