import React from 'react'
import { z } from 'zod'
import ApiKeyField from '../fields/ApiKeyField'
import FormWrapper from '../FormWrapper'
import GenericField from '../fields/GenericField'
import SettingField from '../fields/SettingField'
import { zSetting } from 'app/frontend/base/helpers/forms'

const formSchema = z.object({
  name: z.string().min(1).max(20),
  readonlyKey: z.string().optional(),
  privateKey: z.string().optional(),
  settings: z.object({
    teamMinimumTrackLevel: zSetting(z.coerce.number().min(10).max(120)),
  }),
})

const TeamSection = () => {
  return (
    <FormWrapper formSchema={formSchema}>
      <div className='flex gap-2'>
        <GenericField name='name' />
      </div>

      <SettingField name='teamMinimumTrackLevel' layout='box' />

      <div>
        <ApiKeyField name='readonlyKey' />
        <ApiKeyField name='privateKey' />
      </div>
    </FormWrapper>
  )
}

export default TeamSection
