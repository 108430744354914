import { useTeam } from 'app/frontend/base/config/context/team'
import PremiumBenefits from 'app/frontend/base/pages/GuildDashboard/components/PremiumBenefits'
import React from 'react'

const PremiumSection = () => {
  const { guild } = useTeam()

  return (
    <div>
      <PremiumBenefits
        guild={guild}
        blockClassName='bg-background border border-input'
        legacyTier={guild.legacyPatron?.details.pledge_tier}
        benefits={guild.premiumBenefits || []}
      />
    </div>
  )
}

export default PremiumSection
