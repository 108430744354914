import React from 'react'
import { Button } from '@/components/ui/button'
import { usePageInfo } from 'app/frontend/base/config/queries'
import { useTranslation } from 'react-i18next'
import { refreshLinkedAccountData } from 'app/frontend/base/api/user'
import { useRefreshData } from 'app/frontend/base/helpers'

interface Props {
  label?: string
  variant?: 'outline' | 'default'
}

const RefreshPatreonButton = (props: Props) => {
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()

  const patreonAccount = user?.linkedAccounts.find(
    (account) => account.displayType === 'Patreon',
  )

  const { refreshing, refreshData } = useRefreshData({
    retriever: refreshLinkedAccountData,
    id: patreonAccount?.id,
    successKey: 'profile.patreon-data-refreshed',
    queryToInvalidate: [`pageInfo`],
  })

  return (
    <Button
      variant={props.variant || 'outline'}
      loading={refreshing}
      onClick={refreshData}
    >
      {props.label || t('shared.refresh')}
    </Button>
  )
}

export default RefreshPatreonButton
