import React from 'react'
import { DataTable } from '@/components/ui/data-table'
import { ColumnDef } from '@tanstack/react-table'
import { EquippedItem } from '../../../../types/enums'
import ItemType, {
  itemTypeLabels,
  itemTypes,
} from '../../../../types/enums/ItemType'
import { wowheadUrl } from '../../../helpers'
import { itemQualityClasses } from '../../../../types/enums/ItemQuality'
import ItemIcon from '../../../components/ItemIcon'
import { usePageInfo } from 'app/frontend/base/config/queries'

interface Props {
  character?: wowaudit.Character
}

const GearTable = (props: Props) => {
  const {
    data: { gameVersion },
  } = usePageInfo()

  const gearColumns: ColumnDef<Partial<EquippedItem>>[] = [
    {
      accessorKey: 'name',
      header: 'Item',
      cell: (info) => {
        const itemName = info.getValue() as string | undefined
        if (itemName) {
          return (
            <a
              href={wowheadUrl(gameVersion, info.row.original as EquippedItem)}
              target='_blank'
              rel='noreferrer'
              className={`font-semibold inline-flex gap-2 item-center ${
                itemQualityClasses[info.row.original.quality || 0]
              }`}
            >
              <ItemIcon id={info.row.original.id} />
              <span>{itemName}</span>
            </a>
          )
        } else {
          return (
            <span className='opacity-60 italic'>
              {itemTypeLabels[(info.row.original.slot || '') as ItemType]} slot
              is empty
            </span>
          )
        }
      },
    },
    {
      accessorKey: 'enchant',
      header: 'Enchantments',
      cell: (info) => {
        const sockets = info.row.original.sockets || []
        const socketInfo = (
          <span className='flex items-center gap-1'>
            {sockets.map((socket, index) => (
              <span key={index}>
                {socket.gem ? (
                  <a
                    href={wowheadUrl(gameVersion, undefined, socket.gem)}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <ItemIcon id={socket.gem} />
                  </a>
                ) : (
                  <img
                    src={`https://data.wowaudit.com/img/socket-${socket.type.toLowerCase()}.gif`}
                    className='w-5 h-5'
                  />
                )}
              </span>
            ))}
          </span>
        )

        let missingSockets = 0
        if (
          info.row.original.sockets &&
          ['finger_1', 'finger_2', 'neck'].includes(
            info.row.original.slot || '',
          )
        ) {
          missingSockets = 2 - sockets.length
        }

        const enchant = info.getValue() as EquippedItem['enchant'] | undefined
        const enchantInfo = enchant?.missing ? (
          <span className='text-red-500 italic'>Enchant missing</span>
        ) : (
          <span>{enchant?.name}</span>
        )

        return (
          <span className='flex gap-2'>
            {missingSockets > 0 ? (
              <span className='text-red-500 italic'>
                {missingSockets === 1
                  ? 'One socket missing'
                  : 'Two sockets missing.'}
              </span>
            ) : (
              ''
            )}
            {sockets.length ? socketInfo : null}
            {enchantInfo}
          </span>
        )
      },
    },
    {
      accessorKey: 'upgrade_level_track',
      maxSize: 2,
      header: 'Track',
      size: 100,
      cell: (info) => {
        const upgradeLevel = info.row.original
          .upgrade_level as EquippedItem['upgrade_level']
        return <div>{upgradeLevel?.track || ''}</div>
      },
    },
    {
      accessorKey: 'upgrade_level_progress',
      header: '',
      cell: (info) => {
        const upgradeLevel = info.row.original
          .upgrade_level as EquippedItem['upgrade_level']
        if (!upgradeLevel) return

        if (upgradeLevel.track === 'None' || upgradeLevel.total === 0) {
          return <span>-</span>
        } else {
          return (
            <span
              className={`whitespace-nowrap
                ${
                  upgradeLevel.step === upgradeLevel.total
                    ? `text-primary`
                    : `text-yellow-600 dark:text-yellow-500`
                }`}
            >
              {upgradeLevel.step} / {upgradeLevel.total}
            </span>
          )
        }
      },
    },
    {
      accessorKey: 'ilvl',
      header: () => <div className='text-right'>ILVL</div>,
      cell: (info) => (
        <div className='text-right'>
          {info.getValue() as EquippedItem['ilvl']}
        </div>
      ),
    },
  ]

  const equippedGear = itemTypes
    .filter((slot) => slot !== 'ranged' || gameVersion !== 'live')
    .map((slot) => {
      if (
        props.character?.details?.gear &&
        props.character.details.gear[slot]
      ) {
        return props.character.details.gear[slot]
      } else {
        return { slot } as Partial<EquippedItem>
      }
    })

  return (
    <div className='bg-muted rounded-md'>
      <DataTable
        columns={gearColumns}
        data={equippedGear}
        includeHeader={true}
      />
    </div>
  )
}

export default GearTable
