import React, { useContext, useEffect, useMemo } from 'react'
import { groupBy, sumBy } from 'lodash'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { updatePersonalCharacter } from '../api/user'
import { getPendingFetchCharactersJob } from '../api/job'

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command'
import { Skeleton } from '@/components/ui/skeleton'
import { Star } from 'lucide-react'
import CharacterClassIcon from './CharacterClassIcon'
import { useNavigate } from 'react-router-dom'
import CharacterAvatar from './CharacterAvatar'
import { usePageInfo, useUserCharacterDetails } from '../config/queries'
import { AppContext } from '../config/context/app'

interface Props {
  setOpen: (state: boolean) => void
  open: boolean
}

const CharacterSwitcher = ({ open, setOpen }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: session } = usePageInfo()
  const { setCurrentCharacter } = useContext(AppContext)

  const { data: userCharacterDetails, isLoading } = useUserCharacterDetails(
    !!session.user && open,
  )

  const { data: pendingJob } = useQuery({
    queryKey: ['pendingUserCharactersJob', session.user?.id, open],
    queryFn: getPendingFetchCharactersJob,
    enabled: open,
    refetchInterval: 5000,
  })

  useEffect(() => {
    if (pendingJob && !['queued', 'working'].includes(pendingJob.status)) {
      queryClient.invalidateQueries({ queryKey: ['pageInfo'] })
    }
  }, [pendingJob])

  const sortValueForRealm = (characters: wowaudit.Character[]) => {
    const mainOnRealmValue = characters.find((character) => character.main)
      ? 99999
      : 0

    return (
      mainOnRealmValue + sumBy(characters, (character) => character.level || 0)
    )
  }

  const charactersByRealm = useMemo(() => {
    if (session.user) {
      return Object.entries(
        groupBy(
          [...session.user.characters].sort(
            (a, b) => (a.main ? 0 : 1) - (b.main ? 0 : 1),
          ),
          (character) => `${character.realm.name} (${character.realm.region})`,
        ),
      ).sort((a, b) => sortValueForRealm(b[1]) - sortValueForRealm(a[1]))
    } else {
      return []
    }
  }, [])

  const switchCharacter = (character: wowaudit.Character) => {
    setCurrentCharacter(character)

    if (!session.user?.characters.find((ch) => ch.main)) {
      updatePersonalCharacter(character.id, { main: true })
    }

    navigate(character.path, { state: { character } })
    setOpen(false)
  }

  const infoBox = (value: string, label: string) => {
    return (
      <div className='bg-muted group-hover:bg-popover aria-selected:bg-popover rounded-lg flex items-center justify-center flex-col'>
        <span className='opacity-50 text-[0.7rem]'>{label}</span>
        <div className='mt-[-0.2rem] mb-[0.2rem]'>{value}</div>
      </div>
    )
  }

  return (
    <CommandDialog open={open} onOpenChange={setOpen} centered={false}>
      <CommandInput placeholder={t('character-switcher.choose-character')} />
      <CommandList>
        <CommandEmpty>
          {['queued', 'working'].includes(pendingJob?.status || '')
            ? t('character-switcher.characters-loading')
            : t('character-switcher.no-characters-found')}
        </CommandEmpty>
        {charactersByRealm.map(([realmName, characters]) => (
          <React.Fragment key={realmName}>
            <CommandGroup heading={realmName}>
              {characters.map((character) => {
                return (
                  <CommandItem
                    key={character.id}
                    className='group'
                    value={character.name}
                  >
                    <div
                      className='grid grid-cols-6 gap-2 w-full'
                      onClick={() => switchCharacter(character)}
                    >
                      <span className='col-span-2 flex items-center relative'>
                        <CharacterAvatar
                          character={character}
                          className='h-8 w-8 mr-3'
                        />

                        {character.main && (
                          <div className='absolute top-0 left-[-0.6rem] bg-accent rounded-full w-5 h-5 flex items-center justify-center'>
                            <Star
                              className='max-w-[0.9rem] text-yellow-500'
                              strokeWidth={2.5}
                            />
                          </div>
                        )}

                        <span>{character.name}</span>
                      </span>
                      <div className='flex items-center col-span-2 whitespace-nowrap'>
                        <CharacterClassIcon
                          characterClass={character.characterClass}
                        />
                        <span className='ml-2'>
                          {character.characterClass || 'Unknown'}
                        </span>
                      </div>
                      {infoBox(character.level?.toString() || '-', 'Level')}
                      {isLoading ? (
                        <Skeleton className='group-hover:bg-popover' />
                      ) : (
                        infoBox(
                          userCharacterDetails
                            ?.find((ch) => ch.id === character.id)
                            ?.details?.maxItemLevel?.toString() || '-',
                          'ILVL',
                        )
                      )}
                    </div>
                  </CommandItem>
                )
              })}
            </CommandGroup>
            <CommandSeparator />
          </React.Fragment>
        ))}
      </CommandList>
    </CommandDialog>
  )
}

export default CharacterSwitcher
