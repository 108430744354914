import React, { useMemo } from 'react'

interface Props {
  character?: wowaudit.Character
}

const GearSummary = ({ character }: Props) => {
  if (!character?.details || !character?.refreshedAt) return null

  const liStyles = 'bg-accent my-1 py-1 px-2 rounded-md'

  const enchantsMissing = useMemo(() => {
    if (!character.details?.gear) return 0

    return Object.values(character.details.gear).filter(
      (item) => item.enchant?.missing,
    ).length
  }, [character?.details])

  const socketInfo = useMemo(() => {
    if (!character.details?.gear) return null

    const socketItems = Object.values(character.details.gear).filter(
      (item) => item.sockets?.length,
    )

    const missing = Math.max(
      0,
      6 -
        (character.details.gear.neck?.sockets.length || 0) -
        (character.details.gear.finger_1?.sockets.length || 0) -
        (character.details.gear.finger_2?.sockets.length || 0),
    )

    const empty = socketItems.reduce(
      (acc, item) => acc + item.sockets.filter((socket) => !socket.gem).length,
      0,
    )

    return { missing, empty }
  }, [character?.details])

  const tierItems = character.details.tierItemsEquipped || 0

  return (
    <ul>
      <li className={liStyles}>
        <strong>{character.details.currentItemLevel}</strong> equipped item
        level (max <strong>{character.details.maxItemLevel}</strong>)
      </li>

      <li className={liStyles}>
        <strong
          className={enchantsMissing > 0 ? 'text-red-500' : 'text-primary'}
        >
          {enchantsMissing}
        </strong>{' '}
        enchant{enchantsMissing === 1 ? '' : 's'} missing
      </li>

      <li className={liStyles}>
        <strong
          className={socketInfo?.empty !== 0 ? 'text-red-500' : 'text-primary'}
        >
          {socketInfo?.empty}
        </strong>{' '}
        empty socket{socketInfo?.empty === 1 ? '' : 's'}
      </li>

      <li className={liStyles}>
        <strong
          className={
            socketInfo?.missing !== 0 ? 'text-red-500' : 'text-primary'
          }
        >
          {socketInfo?.missing}
        </strong>{' '}
        jewelry socket{socketInfo?.missing === 1 ? '' : 's'} missing
      </li>

      <li className={liStyles}>
        <strong className={tierItems >= 4 ? 'text-primary' : 'text-yellow-500'}>
          {tierItems} / 5
        </strong>{' '}
        tier items obtained
      </li>

      <li className={liStyles}>
        <strong
          className={
            character.details.embellishmentsEquipped === 2
              ? 'text-primary'
              : 'text-red-500'
          }
        >
          {character.details.embellishmentsEquipped}
        </strong>{' '}
        embellishment
        {character.details.embellishmentsEquipped === 1 ? '' : 's'} equipped
      </li>
    </ul>
  )
}

export default GearSummary
