import React from 'react'
import { useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Main } from '../../components'
import { useQuery } from '@tanstack/react-query'
import { getCharacterDetails } from '../../api/character'
import CharacterHeader from './components/CharacterHeader'
import GearTable from './components/GearTable'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'

import DashboardTile from '../../components/DashboardTile'
import GearSummary from './components/GearSummary'
import UpgradesSummary from './components/UpgradesSummary'
import VaultSummary from './components/VaultSummary'
import TrackingSummary from './components/TrackingSummary'
import { useBlizzardApi, usePrefetchedData } from '../../helpers'

// When navigating directly, characterInfo will be populated (if the character exists). When navigating
// from another page that contains a character reference, propagatedInfo will be populated. If neither exist,
// the visitor can choose to search for the character on the Blizzard API (blizzardInfo).
const CharacterDashboard = () => {
  const { t } = useTranslation()
  const {
    uriRegion,
    uriRealm,
    uriName,
    blizzardInfo,
    blizzardStatus,
    searchBlizzard,
  } = useBlizzardApi<wowaudit.Character>('Character', t)

  const prefetchedInfo = usePrefetchedData<wowaudit.Character>('Character')
  const propagatedInfo = useLocation().state?.character as
    | wowaudit.Character
    | undefined
  const baseCharacter = propagatedInfo || prefetchedInfo || blizzardInfo

  const { data: extendedCharacter, refetch } = useQuery({
    queryKey: [`character_${baseCharacter?.id}`],
    queryFn: () => getCharacterDetails(baseCharacter?.id as number),
    enabled: !!baseCharacter?.id,
    staleTime: 1000 * 60 * 60,
  })

  const character = extendedCharacter || baseCharacter

  return (
    <Main>
      <CharacterHeader
        character={character}
        uriRegion={uriRegion}
        uriRealm={uriRealm}
        uriName={uriName}
      />

      {!baseCharacter?.id ? (
        <div className='mt-8'>
          <Alert variant='warning'>
            <AlertTitle>{t('dashboard.character.not-found')}</AlertTitle>
            <AlertDescription className='mt-4'>
              <p>{t('dashboard.character.not-found-description')}</p>
              {blizzardStatus === 'not-found' ? (
                <p>
                  {t('dashboard.character.not-found-official-api-description')}
                </p>
              ) : (
                <Button
                  className='mt-2 text-foreground'
                  variant='outline'
                  loading={blizzardStatus === 'pending'}
                  spinner='blizzard'
                  onClick={searchBlizzard}
                >
                  {t(`header.search-official-api`)}
                </Button>
              )}
            </AlertDescription>
          </Alert>
        </div>
      ) : null}

      {character && character.gdprStatus !== 'exists' ? (
        <div className='mt-8'>
          <Alert variant='warning'>
            <AlertTitle>{t('dashboard.character.no-longer-exists')}</AlertTitle>
            <AlertDescription className='mt-4'>
              <p>
                <Trans
                  i18nKey='dashboard.character.no-longer-exists-description'
                  components={[
                    <Link
                      key='profile'
                      to='/profile'
                      className='text-primary'
                    />,
                  ]}
                />
              </p>
            </AlertDescription>
          </Alert>
        </div>
      ) : null}

      <React.Fragment>
        <div className='my-8 flex gap-2'>
          <DashboardTile
            title='Gear summary'
            size='medium'
            className='basis-1/4'
            dataPresent={!!character?.details}
          >
            <GearSummary character={character} />
          </DashboardTile>

          <DashboardTile
            title='Item upgrades'
            size='medium'
            className='basis-1/4'
            dataPresent={!!character?.details}
          >
            <UpgradesSummary character={character} />
          </DashboardTile>

          <DashboardTile
            title='Great vault (this week)'
            size='medium'
            className='basis-1/4'
            dataPresent={!!character?.details}
          >
            <VaultSummary character={character} />
          </DashboardTile>

          <DashboardTile
            title='Teams'
            size='medium'
            className='basis-1/4'
            dataPresent={!!character?.details}
          >
            <TrackingSummary character={character} refetch={refetch} />
          </DashboardTile>
        </div>
        <div className='my-8'>
          <GearTable character={character} />
        </div>
      </React.Fragment>
    </Main>
  )
}

export default CharacterDashboard
