import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Check, RotateCcw } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  name: 'readonlyKey' | 'privateKey'
}

const ApiKeyField = ({ name }: Props) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const [keyCopied, setKeyCopied] = useState<string>()

  const formValue = watch(name)

  const originalValue = useMemo(() => formValue, [])

  const copyKey = () => {
    if (formValue && formValue !== 'new-key') {
      navigator.clipboard?.writeText(formValue)
      setKeyCopied(formValue)
      window.getSelection()?.removeAllRanges()

      setTimeout(() => {
        setKeyCopied(undefined)
      }, 3000)
    }
  }

  const action = () => {
    if (formValue === 'new-key' || keyCopied === undefined) {
      return (
        <a
          onClick={() =>
            setValue(name, formValue === 'new-key' ? originalValue : 'new-key')
          }
          className='cursor-pointer font-normal flex gap-1.5 items-center hover:text-white'
        >
          <RotateCcw size={14} />{' '}
          {formValue === 'new-key'
            ? t('shared.undo')
            : t('dashboard.team.settings.reset-key')}
        </a>
      )
    } else {
      return (
        <span className='ml-2 text-primary flex gap-1 items-center'>
          <Check size={14} /> Copied!
        </span>
      )
    }
  }

  return (
    <div className='rounded-md bg-background py-2 px-3 mb-8 text-sm border border-input'>
      <h3 className='flex justify-between font-medium'>
        <span>{t(`dashboard.team.settings.title-${name}`)}</span>
        {action()}
      </h3>

      <Tooltip delayDuration={150}>
        <TooltipContent>
          {t(`dashboard.team.settings.click-to-copy`)}
        </TooltipContent>
        <TooltipTrigger
          disabled={formValue === 'new-key'}
          type='button'
          className={`w-full my-2 ${
            formValue === 'new-key'
              ? 'pointer-events-none'
              : 'pointer-events-auto'
          }`}
        >
          <div
            onClick={copyKey}
            className='rounded-md bg-muted py-2 px-3 w-full'
          >
            {formValue === 'new-key' ? (
              <span className='opacity-50'>
                {t(`dashboard.team.settings.new-key-notice`)}
              </span>
            ) : (
              formValue ?? <span className='opacity-50'>-</span>
            )}
          </div>
        </TooltipTrigger>
      </Tooltip>

      <p className='text-muted-foreground opacity-90'>
        {t(`dashboard.team.settings.description-${name}`)}
      </p>
    </div>
  )
}

export default ApiKeyField
