import React, { useState } from 'react'
import { PointDistribution } from 'app/frontend/types/enums/PremiumBenefitCategory'
import { usePageInfo } from '../../../config/queries'
import { displayErrorToast, useSubscription } from '../../../helpers'
import PremiumModal from 'app/frontend/base/components/PremiumModal'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import {
  grantLegacyBenefits,
  revokeLegacyBenefits,
} from 'app/frontend/base/api/user'
import { useQueryClient } from '@tanstack/react-query'
import { legacyPoints } from './PremiumBenefits'
import { LegacyPledgeTier, SubscriptionTier } from 'app/frontend/types/enums'

interface Props {
  guild: Partial<wowaudit.Guild>
  totalPointsSpent: PointDistribution
  benefits: wowaudit.PremiumBenefit[]
  previewingTier?: SubscriptionTier
  className: string
}

const PremiumBenefitSummary = ({
  guild,
  benefits,
  totalPointsSpent,
  previewingTier,
  children,
  className,
}: React.PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const [legacySaving, setLegacySaving] = useState(false)
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()

  const {
    legacySubscription,
    subscription,
    subscriptionName,
    subscriptionActive,
  } = useSubscription(user)

  const grantedLegacyBenefits =
    guild.legacyPatron && guild.legacyPatron?.id === legacySubscription?.id

  const subscriptionUsage = subscription
    ? subscription.premiumBenefits.reduce(
        (acc, benefit) => acc + benefit.points,
        0,
      ) -
      benefits
        .filter((b) => b.userId === user?.id)
        .reduce((acc, b) => acc + b.points, 0)
    : 0 + totalPointsSpent.own

  const updateLegacyPlan = (type: 'link' | 'unlink') => {
    if (legacySubscription && guild.id) {
      setLegacySaving(true)
      const endpoint =
        type === 'link' ? grantLegacyBenefits : revokeLegacyBenefits

      endpoint(legacySubscription.id, guild.id, 'Guild')
        .then(async () => {
          queryClient
            .invalidateQueries({ queryKey: ['guild', { id: guild.id }] })
            .then(() => setLegacySaving(false))
        })
        .catch((err) => {
          displayErrorToast(err, t)
          setLegacySaving(false)
        })
    }
  }

  const availablePoints =
    (previewingTier
      ? parseInt(t(`premium.tiers.${previewingTier.toLowerCase()}.boosts`))
      : subscription?.points) || 0

  return (
    <div className={`${className} p-3 rounded-md text-sm`}>
      <div className='flex gap-2 mb-2'>
        <div className='bg-accent rounded-md py-2 px-3 text-sm grow flex flex-col justify-center'>
          <div className='flex gap-2 items-center mb-2'>
            <div className='h-6 w-6 bg-green-200 rounded-full' />
            <div>
              {totalPointsSpent.otherOrLegacy -
                (grantedLegacyBenefits
                  ? legacyPoints[subscriptionName as LegacyPledgeTier].total
                  : 0)}{' '}
              boosts applied by others{' '}
              {grantedLegacyBenefits ? '' : 'or legacy plans'}
            </div>
          </div>

          <div className='flex gap-2 items-center'>
            <div
              className={`h-6 w-6 ${
                grantedLegacyBenefits ? 'bg-green-200' : 'bg-primary'
              } rounded-full`}
            />
            {grantedLegacyBenefits ? (
              <div className='text-primary'>
                Static benefits granted by you!
              </div>
            ) : (
              <div>{totalPointsSpent.own} dynamic boosts applied by you</div>
            )}
          </div>
        </div>

        <div className='bg-accent rounded-md py-2 px-3 flex items-center'>
          <div className='flex flex-col items-center gap-1'>
            <span className='text-2xl'>
              <span
                className={`font-bold ${
                  subscriptionUsage > availablePoints
                    ? 'text-red-500'
                    : subscriptionUsage === availablePoints
                    ? 'text-primary'
                    : 'text-yellow-500'
                }`}
              >
                {subscriptionUsage}
              </span>{' '}
              / {availablePoints}
            </span>{' '}
            <span>{legacySubscription ? 'dynamic' : ''} boosts used</span>
          </div>
        </div>
      </div>

      {previewingTier ? null : (
        <div className='flex gap-2 justify-end items-center mb-1'>
          {subscriptionUsage > availablePoints ? (
            <span className='text-yellow-500'>
              Not enough boosts, please{' '}
              <PremiumModal>
                <a className='text-primary cursor-pointer'>upgrade</a>
              </PremiumModal>{' '}
              your plan to continue
            </span>
          ) : null}

          {!subscriptionActive ? (
            <PremiumModal>
              <Button variant='default' size='sm'>
                {t('shared.subscribe')}
              </Button>
            </PremiumModal>
          ) : null}

          {legacySubscription ? (
            <Button
              size='sm'
              variant={grantedLegacyBenefits ? 'outline' : 'default'}
              onClick={() => {
                if (grantedLegacyBenefits) {
                  updateLegacyPlan('unlink')
                } else if (guild.legacyPatron) {
                  if (confirm(t('premium.overwrite-static-benefits'))) {
                    updateLegacyPlan('link')
                  }
                } else {
                  updateLegacyPlan('link')
                }
              }}
              loading={legacySaving}
            >
              {grantedLegacyBenefits
                ? t('premium.revoke-benefits', {
                    level: previewingTier || subscriptionName,
                  })
                : t('premium.grant-benefits', {
                    level: previewingTier || subscriptionName,
                  })}
            </Button>
          ) : null}
          {subscription ? children : null}
        </div>
      )}
    </div>
  )
}

export default PremiumBenefitSummary
