import { Checkbox } from '@/components/ui/checkbox'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { MultiSelect } from '@/components/ui/multi-select'
import Select from 'app/frontend/base/components/Select'
import React from 'react'
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  name: string
  layout?: 'box' | 'inline'
}

const SettingField = ({ layout = 'inline', name }: Props) => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  const setting: wowaudit.Setting<unknown> = watch(`settings.${name}`)

  const transformOptions = (value?: string[]) => {
    return (value || []).map((option) => ({
      label: t(`settings.${name}.options.${option}`),
      value: option,
    }))
  }

  const fieldElement = (
    field: ControllerRenderProps<FieldValues, `settings.${string}.value`>,
  ) => {
    if (setting.type === 'boolean') {
      return <Checkbox checked={field.value} onCheckedChange={field.onChange} />
    }

    if (setting.multiple) {
      const options = transformOptions(setting.options || [])
      const defaultValue = transformOptions((setting.value as string[]) || [])
      return (
        <MultiSelect<string>
          options={options}
          defaultValue={defaultValue}
          onChange={(selected) =>
            setValue(
              `settings.${name}.value`,
              selected.map((o) => o.value),
            )
          }
        />
      )
    }

    if (setting.options) {
      return (
        <Select
          value={setting.value as string}
          options={transformOptions(setting.options || [])}
          setValue={(value) => setValue(`settings.${name}.value`, value)}
        />
      )
    }

    return (
      <Input
        type={setting.type}
        {...field}
        className={
          layout === 'box' ? 'absolute right-3 top-1.5 w-24 bg-muted z-20' : ''
        }
        value={(setting.value as string) ?? ''}
        onChange={(event) =>
          setValue(`settings.${name}.value`, event.target.value ?? '')
        }
      />
    )
  }

  return (
    <div className='mb-8'>
      <FormField
        control={control}
        name={`settings.${name}.value`}
        render={({ field }) => {
          return (
            <FormItem
              className={`grow ${
                layout === 'box'
                  ? 'bg-background py-2 px-3 rounded-md relative border border-input'
                  : ''
              }`}
            >
              <FormLabel className={layout === 'box' ? 'mr-24' : ''}>
                {t(`settings.${name}.label`)}
              </FormLabel>
              <FormControl
                className={layout === 'box' ? 'absolute right-6 top-4' : ''}
              >
                {fieldElement(field)}
              </FormControl>
              <FormDescription className={layout === 'box' ? 'mr-32' : ''}>
                {t(`settings.${name}.description`)}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )
        }}
      />
    </div>
  )
}

export default SettingField
