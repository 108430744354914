import React, { useEffect, useState } from 'react'
import { useRoutes, useLocation } from 'react-router-dom'

import { Footer, Header } from './components'

import {
  ApiOverview,
  CharacterDashboard,
  CommunityDashboard,
  GuildDashboard,
  GuildMembers,
  GuildNews,
  GuildOverview,
  GuildPremium,
  GuildTeams,
  Home,
  Onboarding,
  PrivacyPolicy,
  Profile,
  TeamDashboard,
  TermsOfUse,
  UserDashboard,
} from './pages'
import { AppContext, defaultCharacter } from './config/context/app'
import LoginFailure from './pages/LoginFailure'
import { usePageInfo } from './config/queries'
import NotFound from './pages/Error/NotFound'
import TeamOverview from './pages/TeamDashboard/subpages/TeamOverview'
import TeamRoster from './pages/TeamDashboard/subpages/TeamRoster'
import TeamApplicants from './pages/TeamDashboard/subpages/TeamApplicants'
import TeamEvents from './pages/TeamDashboard/subpages/TeamEvents'
import TeamLoot from './pages/TeamDashboard/subpages/TeamLoot'
import TeamSettings from './pages/TeamDashboard/subpages/TeamSettings'
import TeamSpreadsheet from './pages/TeamDashboard/subpages/TeamSpreadsheet'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  const { data: session } = usePageInfo()
  const [currentCharacter, setCurrentCharacter] = useState(
    defaultCharacter(session),
  )

  const setAndStoreCurrentCharacter = (character?: wowaudit.Character) => {
    setCurrentCharacter(character)
    if (character)
      localStorage.setItem('currentCharacterId', character.id.toString())
  }

  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/onboarding', element: <Onboarding /> },
    { path: '/profile', element: <Profile /> },
    { path: '/api', element: <ApiOverview /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-use', element: <TermsOfUse /> },
    { path: '/auth/failure', element: <LoginFailure /> },
    {
      path: '/guild/:region/:realm/:guild',
      children: [
        {
          index: true,
          element: (
            <GuildDashboard scope='guild'>
              <GuildOverview />
            </GuildDashboard>
          ),
        },
        {
          path: 'premium',
          element: (
            <GuildDashboard scope='guild'>
              <GuildPremium />
            </GuildDashboard>
          ),
        },
        {
          path: 'news',
          element: (
            <GuildDashboard scope='guild'>
              <GuildNews scope='guild' />
            </GuildDashboard>
          ),
        },
        {
          path: 'members',
          element: (
            <GuildDashboard scope='guild'>
              <GuildMembers />
            </GuildDashboard>
          ),
        },
        {
          path: 'teams',
          element: (
            <GuildDashboard scope='guild'>
              <GuildTeams />
            </GuildDashboard>
          ),
        },
        {
          path: 'teams/:team',
          children: [
            {
              index: true,
              element: (
                <GuildDashboard scope='team'>
                  <TeamOverview />
                </GuildDashboard>
              ),
            },
            {
              path: 'news',
              element: (
                <GuildDashboard scope='team'>
                  <GuildNews scope='currentTeam' />
                </GuildDashboard>
              ),
            },
            {
              path: 'roster',
              element: (
                <GuildDashboard scope='team'>
                  <TeamRoster />
                </GuildDashboard>
              ),
            },
            {
              path: 'applicants',
              element: (
                <GuildDashboard scope='team'>
                  <TeamApplicants />
                </GuildDashboard>
              ),
            },
            {
              path: 'events',
              element: (
                <GuildDashboard scope='team'>
                  <TeamEvents />
                </GuildDashboard>
              ),
            },
            {
              path: 'loot',
              element: (
                <GuildDashboard scope='team'>
                  <TeamLoot />
                </GuildDashboard>
              ),
            },
            {
              path: 'settings',
              element: (
                <GuildDashboard scope='team'>
                  <TeamSettings />
                </GuildDashboard>
              ),
            },
            {
              path: 'spreadsheet',
              element: (
                <GuildDashboard scope='team'>
                  <TeamSpreadsheet />
                </GuildDashboard>
              ),
            },
            {
              path: '*',
              element: (
                <GuildDashboard scope='team'>
                  <NotFound />
                </GuildDashboard>
              ),
            },
          ],
        },
        {
          path: '*',
          element: (
            <GuildDashboard scope='guild'>
              <NotFound />
            </GuildDashboard>
          ),
        },
      ],
    },
    {
      path: '/character/:region/:realm/:character',
      element: <CharacterDashboard />,
    },
    {
      path: '/community/:community',
      children: [
        { index: true, element: <CommunityDashboard /> },
        {
          path: 'teams/:team',
          children: [{ index: true, element: <TeamDashboard /> }],
        },
      ],
    },
    {
      path: '/dashboard',
      children: [{ index: true, element: <UserDashboard /> }],
    },
    {
      // Personal user team
      path: '/personal/team',
      children: [{ index: true, element: <TeamDashboard /> }],
    },
  ])

  return (
    <AppContext.Provider
      value={{
        currentCharacter,
        setCurrentCharacter: setAndStoreCurrentCharacter,
      }}
    >
      <Header />
      {routes}
      <ScrollToTop />
      <Footer />
    </AppContext.Provider>
  )
}

export default App
