import { getRequest, postRequest, putRequest } from '.'

export const getCharacterDetails = async (
  id: number,
): Promise<wowaudit.Character> => {
  return await getRequest(`/api/characters/${id}`)
}

export const updateCharacter = async (
  id: number,
): Promise<{ job_id: string }> => {
  return await putRequest(`/api/characters/${id}`, {})
}

export const transferCharacter = async (
  id: number,
  data: { realm: wowaudit.Realm; name: string },
): Promise<{ job_id: string }> => {
  return await postRequest(`/api/characters/${id}/transfer`, data)
}
