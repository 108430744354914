import { useGuild } from 'app/frontend/base/config/context/guild'
import React from 'react'

const GuildOverview = () => {
  const { guild } = useGuild()

  return <div>GuildOverview! {guild.id}</div>
}

export default GuildOverview
