import { createContext } from 'react'

export const defaultCharacter = (pageInfo: wowaudit.PageInfo) => {
  let foundCharacter: wowaudit.Character | undefined
  const storedCharacterId = parseInt(
    localStorage.getItem('currentCharacterId') || '',
  )

  if (storedCharacterId) {
    foundCharacter = pageInfo.user?.characters.find(
      (character) => character.id === storedCharacterId,
    )
  }
  if (foundCharacter) return foundCharacter

  localStorage.removeItem('currentCharacterId')
  foundCharacter = pageInfo.user?.characters.find((character) => character.main)

  return foundCharacter
}

export interface AppContextType {
  currentCharacter?: wowaudit.Character
  setCurrentCharacter: (character: wowaudit.Character | undefined) => void
}

export const AppContext = createContext<AppContextType>({
  setCurrentCharacter: () => {},
})

export default AppContext
