import React from 'react'
import { TFunction } from 'i18next'
import {
  CellContext,
  HeaderContext,
  SortingFnOption,
} from '@tanstack/react-table'

import CharacterAvatar from '../../components/CharacterAvatar'
import Timestamp from '../../components/Timestamp'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { ChevronDown, Edit } from 'lucide-react'
import { Link } from 'react-router-dom'
import CharacterClassIcon from '../../components/CharacterClassIcon'
import TeamCard from '../../components/TeamCard'

const genericSorting = (
  sortingFn: SortingFnOption<wowaudit.Character>,
  label: string | JSX.Element,
  sortDescFirst: boolean = false,
) => {
  return {
    sortingFn,
    sortDescFirst,
    sortUndefined: 'last' as false | -1 | 1 | 'first' | 'last',
    header: ({ column }: HeaderContext<wowaudit.Character, unknown>) => (
      <span className='cursor-pointer' onClick={() => column.toggleSorting()}>
        {label}
      </span>
    ),
  }
}

export const name = {
  accessorKey: 'name',
  id: 'name',
  size: 175,
  ...genericSorting('text', 'Name'),
  cell: (info: CellContext<wowaudit.Character, unknown>) => {
    return (
      <div className='flex'>
        <Link
          to={info.row.original.path}
          className='inline-flex gap-2 items-center'
          state={{ character: info.row.original }}
        >
          <CharacterAvatar character={info.row.original} className='h-6 w-6' />

          {info.row.original.name}
        </Link>
      </div>
    )
  },
}

export const characterClass = {
  accessorKey: 'characterClass',
  id: 'characterClass',
  size: 175,
  ...genericSorting('text', 'Class'),
  cell: (info: CellContext<wowaudit.Character, unknown>) => {
    return (
      <span className='flex gap-2 items-center'>
        <CharacterClassIcon characterClass={info.row.original.characterClass} />
        <span>{info.row.original.characterClass}</span>
      </span>
    )
  },
}

export const realm = {
  accessorKey: 'realm.name',
  id: 'realm',
  ...genericSorting('text', 'Realm'),
  cell: (info: CellContext<wowaudit.Character, unknown>) => {
    return info.row.original.realm.nameWithRegion
  },
}

export const level = {
  accessorKey: 'level',
  id: 'level',
  size: 75,
  ...genericSorting('alphanumeric', 'Level', true),
}

export const itemLevel = (extendedDetails?: wowaudit.Character[]) => {
  return {
    accessorKey: 'details.maxItemLevel',
    id: 'itemLevel',
    size: 75,
    ...genericSorting('alphanumeric', 'iLvl', true),
    cell: (info: CellContext<wowaudit.Character, unknown>) => {
      const extendedCharacter = extendedDetails
        ? extendedDetails?.find((ch) => ch.id === info.row.original.id)
        : info.row.original

      return (
        <div
          className={`transition-opacity duration-300 w-8 ${
            extendedCharacter ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {extendedCharacter?.details?.maxItemLevel || '-'}
        </div>
      )
    },
  }
}

export const teams = (t: TFunction, extendedDetails?: wowaudit.Character[]) => {
  return {
    accessorKey: 'teams.length',
    id: 'teams',
    size: 110,
    ...genericSorting('alphanumeric', 'Teams', true),
    cell: (info: CellContext<wowaudit.Character, unknown>) => {
      const extendedCharacter = extendedDetails
        ? extendedDetails?.find((ch) => ch.id === info.row.original.id)
        : info.row.original
      const teams = extendedCharacter?.teams || []

      return (
        <div
          className={`transition-opacity duration-300 w-[5.5rem] ${
            extendedCharacter ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {teams.length ? (
            <Popover>
              <PopoverTrigger className='flex items-center gap-2'>
                {t('profile.character-table.team-amount', {
                  count: teams.length,
                })}
                <ChevronDown size={12} />
              </PopoverTrigger>
              <PopoverContent align='end' className='text-sm'>
                {teams.map((team) => (
                  <TeamCard key={team.id} team={team} />
                ))}
              </PopoverContent>
            </Popover>
          ) : (
            <span className='italic opacity-70'>{t('shared.none')}</span>
          )}
        </div>
      )
    },
  }
}

export const rankInGuild = {
  accessorKey: 'rankInGuild',
  id: 'rankInGuild',
  size: 50,
  ...genericSorting('alphanumeric', 'Rank', false),
}

export const refreshedAt = (
  t: TFunction,
  source: 'user' | 'guild',
  action?: (character: wowaudit.Character) => JSX.Element,
) => {
  return {
    accessorKey: 'refreshedAt',
    id: 'refreshedAt',
    size: 175,
    ...genericSorting(
      'alphanumeric',
      <div className='text-right'>Details updated</div>,
      true,
    ),
    align: 'right',
    cell: (info: CellContext<wowaudit.Character, unknown>) => {
      return (
        <div className='text-right'>
          {info.row.original.gdprStatus === 'exists' ? (
            <Timestamp time={info.row.original.refreshedAt} />
          ) : (
            <div className='flex items-center justify-end gap-2'>
              {source === 'user' ? (
                <Popover>
                  <PopoverTrigger asChild className='flex items-center gap-2'>
                    <Edit
                      size={14}
                      className='cursor-pointer opacity-70 hover:opacity-100'
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    align='end'
                    className='text-sm text-center w-[9rem]'
                  >
                    <div className='mb-2'>{t('shared.mark-as')}</div>
                    <div className='flex flex-col gap-1'>
                      {action ? action(info.row.original) : null}
                    </div>
                  </PopoverContent>
                </Popover>
              ) : null}
              <span className='italic opacity-70'>
                {t(
                  `profile.character-gdpr-status.${info.row.original.gdprStatus}`,
                ).toLowerCase()}
              </span>
            </div>
          )}
        </div>
      )
    },
  }
}
