import React from 'react'
import { z } from 'zod'
import SettingField from '../fields/SettingField'
import FormWrapper from '../FormWrapper'
import { zSetting } from 'app/frontend/base/helpers/forms'

const formSchema = z.object({
  settings: z.object({
    raidsAvailableStatuses: zSetting(z.array(z.string())),
    raidsAutoLock: zSetting(z.number().or(z.string())),
    raidsAllowGuests: zSetting(z.string()),
  }),
})

const EventSection = () => {
  return (
    <FormWrapper formSchema={formSchema}>
      <SettingField name='raidsAvailableStatuses' />
      <SettingField name='raidsAllowGuests' />
      <SettingField name='raidsAutoLock' layout='box' />
    </FormWrapper>
  )
}

export default EventSection
