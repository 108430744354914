import { useGuild } from 'app/frontend/base/config/context/guild'
import React from 'react'

const HeaderTiles = () => {
  const { currentTeam } = useGuild()

  return <div></div>
}

export default HeaderTiles
