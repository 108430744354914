import { useTeam } from 'app/frontend/base/config/context/team'
import React from 'react'

const TeamOverview = () => {
  const { team } = useTeam()

  return <div>TeamOverview! {team.id}</div>
}

export default TeamOverview
