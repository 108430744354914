import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { updateCharacter } from '../../../api/character'
import { useRefreshData } from '../../../helpers'
import { toast } from 'sonner'
import dayjs from 'dayjs'

import { Button } from '@/components/ui/button'

import Timestamp from 'app/frontend/base/components/Timestamp'
import TeamCard from 'app/frontend/base/components/TeamCard'

interface Props {
  character?: wowaudit.Character
  refetch: () => void
}

const TrackingSummary = ({ character, refetch }: Props) => {
  const { t } = useTranslation()

  const { refreshing, refreshData, updateTriggeredAt } = useRefreshData({
    retriever: updateCharacter,
    id: character?.id,
    successKey: 'dashboard.character.character-update-success',
    callback: refetch,
  })

  useEffect(() => {
    if (updateTriggeredAt && character?.refreshFailedAt) {
      if (dayjs(updateTriggeredAt).isBefore(dayjs(character.refreshFailedAt))) {
        toast.error(
          t(`errors.character_update_error`, {
            code: character.lastStatusCode,
          }),
        )
      }
    }
  }, [character?.refreshFailedAt])

  return (
    <div className='min-h-[10rem]'>
      <p>
        {character?.teams?.length
          ? t('dashboard.character.tracked-in-teams', {
              count: character?.teams?.length,
            })
          : t('dashboard.character.not-tracked-in-teams')}
      </p>
      {(character?.teams || []).map((team) => (
        <TeamCard key={team.id} team={team} />
      ))}

      <div className='mt-3 mb-1 pb-1 block border-b border-accent font-bold'>
        {t('shared.last-updated')}
      </div>

      {character?.details && !character?.refreshedAt ? (
        <p className='italic opacity-70'>
          {t('dashboard.character.never-refreshed')}
        </p>
      ) : null}

      <div className='flex justify-between items-center'>
        <Timestamp time={character?.refreshedAt} colorNever={true} />

        <Button
          variant='outline'
          size='sm'
          loading={refreshing}
          spinner='blizzard'
          disabled={!character?.id}
          onClick={refreshData}
        >
          {t('shared.update-now')}
        </Button>
      </div>
    </div>
  )
}

export default TrackingSummary
