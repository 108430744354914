import React, { useMemo, useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import Select from 'app/frontend/base/components/Select'

import cron from 'cron-validate'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import GenericFormError from 'app/frontend/base/components/GenericFormError'
import { useTranslation } from 'react-i18next'
import { useNotificationTemplateOptions } from 'app/frontend/base/config/queries'
import { notificationCtaTargets } from 'app/frontend/types/enums/NotificationTemplateCtaTarget'
import { notificationDeliveryMethods } from 'app/frontend/types/enums/NotificationTemplateMethod'
import { notificationDeliveryTargets } from 'app/frontend/types/enums/NotificationTemplateTarget'

const scheduleSchemaOptions = {
  cronSchedule: z.string().superRefine((data, context) => {
    if (!data || !data.length) return

    const cronResult = cron(data)
    if (!cronResult.isValid()) return

    for (const error of cronResult.getError()) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: error,
      })
    }
  }),
}

const noScheduleSchemaOptions = {
  delayInMinutes: z.number().int().min(0),
  cooldown: z.number().int().min(1),
}

const deliveryTargetUserSchemaOptions = {
  deliveryTargetBattletag: z
    .string()
    .refine(
      (value) =>
        /(^([A-zÀ-ú][A-zÀ-ú0-9]{2,11})|(^([а-яёА-ЯЁÀ-ú][а-яёА-ЯЁ0-9À-ú]{2,11})))(#[0-9]{4,})$/.test(
          value,
        ),
      'Please enter a valid Battletag',
    ),
}

const createFormSchema = (
  typeOptions: wowaudit.NotificationTemplateOption[],
) => {
  return z
    .object({
      type: z.string(),
      delayInMinutes: z.number().int().min(0),
      messageTitle: z.string(),
      messageTemplate: z.string(),
      ctaLabel: z.string().optional(),
      customCtaUrl: z.string().optional(),
      ctaTarget: z.enum(notificationCtaTargets),
      deliveryMethod: z.enum(notificationDeliveryMethods),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal('NotificationTemplate::CharacterGear'),
          ...noScheduleSchemaOptions,
          deliveryTarget: z.enum(notificationDeliveryTargets),
        }),
        z.object({
          type: z.literal('NotificationTemplate::CharacterUnlinked'),
          ...scheduleSchemaOptions,
          deliveryTarget: z.enum(['team', 'user']),
        }),
      ]),
    )
}

interface Props {
  template: Partial<wowaudit.NotificationTemplate>
  open: boolean
  setOpen: (open: boolean) => void
}

const NotificationModal = ({
  template,
  open,
  setOpen,
}: React.PropsWithChildren<Props>) => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string>()
  const { t } = useTranslation()
  const { data: typeOptions, isLoading } = useNotificationTemplateOptions()

  if (isLoading || !typeOptions) return null

  const formSchema = createFormSchema(typeOptions)
  const form = useForm<z.infer<typeof formSchema> & { genericError?: string }>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  })

  const localizedTypeOptions = useMemo(() => {
    if (!typeOptions) return []

    return typeOptions.map((option) => ({
      label: t(`notifications.types.${option.type}.label`),
      value: option.className,
      category: option.category,
      premium: option.premium,
    }))
  }, [typeOptions])

  const templateType = form.watch('type')

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setError(undefined)
    setSubmitting(true)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(
              `notifications.edit-modal-title-${
                template?.id ? 'existing' : 'new'
              }`,
            )}
          </DialogTitle>
          <DialogDescription>
            {t(
              `notifications.edit-modal-intro-${
                template?.id ? 'existing' : 'new'
              }`,
            )}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} id='transfer-form'>
            <FormItem>
              <FormLabel>{t('notifications.trigger')}</FormLabel>
              <Select
                value={templateType}
                options={localizedTypeOptions}
                inModal={true}
                setValue={(value) => {
                  if (value) form.setValue(`type`, value)
                }}
              />
            </FormItem>

            <div className='flex gap-2'></div>

            <GenericFormError form={form} customError={error} />
          </form>
        </Form>

        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Cancel
            </Button>
          </DialogClose>
          <Button
            type='submit'
            form='transfer-form'
            loading={submitting}
            spinner='blizzard-white'
          >
            Transfer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default NotificationModal
