import { Button } from '@/components/ui/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LogOut } from 'lucide-react'

const ProfileHeader = () => {
  const { t } = useTranslation()

  return (
    <header className='border-b-2 ml-[-2rem] mr-[-2rem] h-[5.1rem] flex items-end pb-4'>
      <div className='ml-[2rem] mr-[2rem] grow flex justify-between items-center'>
        <h1 className='text-[2rem] font-bold'>{t('profile.profile')}</h1>
        <a href='/logout'>
          <Button variant='outline' className='flex gap-2'>
            <LogOut width={14} />
            {t('header.log-out')}
          </Button>
        </a>
      </div>
    </header>
  )
}

export default ProfileHeader
