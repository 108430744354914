import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormStateContext from 'app/frontend/base/config/context/forms'
import { useTeam } from 'app/frontend/base/config/context/team'
import { useUpdateEntity } from 'app/frontend/base/config/mutations'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z, ZodRawShape } from 'zod'

interface Props {
  formSchema: z.ZodObject<ZodRawShape, z.UnknownKeysParam>
}

const FormWrapper = ({ formSchema, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  const { team } = useTeam()
  const { setHasUnsavedChanges } = useContext(FormStateContext)
  const mutation = useUpdateEntity<wowaudit.Team>('team', team.id)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: team,
  })
  const { isDirty } = form.formState

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutation.mutate(values, { onSuccess: (data) => form.reset(data) })
  }

  useEffect(() => {
    setHasUnsavedChanges(isDirty)

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {children}

        <div className='flex justify-end mt-6'>
          <Button
            type='submit'
            loading={mutation.isPending}
            variant='outline'
            size='sm'
          >
            {t('shared.save-changes')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default FormWrapper
