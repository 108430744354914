import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import React from 'react'
import { Link } from 'react-router-dom'
import { useActionable } from '../helpers'
import { usePageInfo } from '../config/queries'

interface Props {
  menuItems: { name: string; path: string }[]
  state: {
    guild?: Partial<wowaudit.Guild>
    team?: wowaudit.Team
  }
}

const Subheader = ({ menuItems, state }: Props) => {
  const {
    data: { user },
  } = usePageInfo()
  const actionable = useActionable(user)

  return (
    <div
      className={`border-b-2 ml-[-2rem] mr-[-2rem] sticky ${
        actionable ? 'top-[88px]' : 'top-[57px]'
      } bg-accent z-50`}
    >
      <NavigationMenu delayDuration={50}>
        <NavigationMenuList className='mx-[2rem] space-x-0'>
          {menuItems.map((item) => {
            const active = window.location.pathname === item.path

            return (
              <NavigationMenuItem key={item.name}>
                <NavigationMenuLink
                  active={active}
                  className={`${navigationMenuTriggerStyle()} mb-[-2px] bg-transparent hover:bg-muted border-b-2 border-b-transparent data-[active]:border-b-foreground rounded-none`}
                  asChild
                >
                  <Link to={item.path} state={state}>
                    {item.name}
                  </Link>
                </NavigationMenuLink>
              </NavigationMenuItem>
            )
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  )
}

export default Subheader
