import React from 'react'
import * as ActiveStorage from '@rails/activestorage'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Wrapper from '../base/Wrapper'

import '../stylesheets/globals.css'
import '../base/config/i18n'

ActiveStorage.start()

const container = document.createElement('div')
container.className += 'relative flex min-h-screen flex-col'
document.body.appendChild(container)
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <Wrapper />
  </BrowserRouter>,
)
