import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  name: string
  type?: 'string' | 'number'
}

const GenericField = ({ name, type = 'string' }: Props) => {
  const { t } = useTranslation()
  const form = useFormContext()

  return (
    <div className='mb-8 w-full'>
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => {
          return (
            <FormItem className='grow'>
              <FormLabel>{t(`forms.fields.${name}.label`)}</FormLabel>
              <FormControl>
                <Input
                  type={type}
                  placeholder={t(`forms.fields.${name}.placeholder`)}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
    </div>
  )
}

export default GenericField
