import React from 'react'
import { Main } from '../../components'

const PrivacyPolicy = () => {
  return (
    <Main>
      <div>PrivacyPolicy!</div>
    </Main>
  )
}

export default PrivacyPolicy
