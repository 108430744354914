import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import React, { useState } from 'react'
import AccessSection from './components/sections/AccessSection'
import EventSection from './components/sections/EventSection'
import DiscordBotSection from './components/sections/DiscordBotSection'
import LootSection from './components/sections/LootSection'
import NotificationSection from './components/sections/NotificationSection'
import PremiumSection from './components/sections/PremiumSection'
import RecruitmentSection from './components/sections/RecruitmentSection'
import TeamSection from './components/sections/TeamSection'
import { Separator } from '@/components/ui/separator'
import FormStateContext from 'app/frontend/base/config/context/forms'
import ConfirmDialog from 'app/frontend/base/components/ConfirmDialog'
import RankSection from './components/sections/RankSection'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const TeamSettings = () => {
  const { t } = useTranslation()
  const { hash: initialSection } = useLocation()

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [activeSection, setActiveSection] = useState(
    initialSection ? initialSection.replace('#', '') : 'team',
  )
  const [pendingSection, setPendingSection] = useState<string>()

  const sections = {
    team: TeamSection,
    events: EventSection,
    loot: LootSection,
    ranks: RankSection,
    recruitment: RecruitmentSection,
    discord_bot: DiscordBotSection,
    notifications: NotificationSection,
    access: AccessSection,
    premium: PremiumSection,
  }

  const handleSectionChange = (section: string) => {
    setActiveSection(section)
    setPendingSection(undefined)
    history.replaceState({}, '', `#${section}`)
  }

  return (
    <div>
      <h2 className='font-bold text-2xl mb-4'>Settings</h2>

      <ConfirmDialog
        open={!!pendingSection}
        descriptionKey={'forms.unsaved-changes-warning'}
        onCancel={() => setPendingSection(undefined)}
        onContinue={() => {
          handleSectionChange(pendingSection || 'team')
        }}
      />

      <div
        className={`bg-muted rounded-md pt-3 px-4 pb-6 ${
          activeSection === 'premium' ? 'max-w-6xl' : 'max-w-3xl'
        }`}
      >
        <Tabs
          value={activeSection}
          onValueChange={(value) => {
            if (hasUnsavedChanges) {
              setPendingSection(value)
            } else {
              handleSectionChange(value)
            }
          }}
        >
          <TabsList>
            {Object.keys(sections).map((section) => (
              <TabsTrigger
                className='data-[state=active]:text-primary'
                key={section}
                value={section}
              >
                <div>{t(`settings.sections.${section}`)}</div>
              </TabsTrigger>
            ))}
          </TabsList>

          <Separator className='mt-2 mb-6' />

          {Object.entries(sections).map(([section, Component]) => (
            <TabsContent key={section} value={section}>
              <FormStateContext.Provider
                value={{ hasUnsavedChanges, setHasUnsavedChanges }}
              >
                <Component />
              </FormStateContext.Provider>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  )
}

export default TeamSettings
