import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormField, FormItem, FormMessage } from '@/components/ui/form'

const GenericFormError = ({
  form,
  customError,
}: {
  form: UseFormReturn<any>
  customError?: string
}) => {
  return (
    <FormField
      control={form.control}
      name='genericError'
      render={() => (
        <FormItem className='mt-4'>
          <FormMessage>{customError}</FormMessage>
        </FormItem>
      )}
    />
  )
}

export default GenericFormError
