import { getRequest, putRequest } from '.'

export const getGuildDetails = async (id: number): Promise<wowaudit.Guild> => {
  return await getRequest(`/api/guilds/${id}`)
}

export const getGuildMembers = async (
  id: number,
): Promise<wowaudit.Character[]> => {
  return await getRequest(`/api/guilds/${id}/characters`)
}

export const updateGuild = async (id: number): Promise<{ job_id: string }> => {
  return await putRequest(`/api/guilds/${id}`, {})
}
