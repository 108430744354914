import { createContext } from 'react'

export interface FormStateContextType {
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (value: boolean) => void
}

export const FormStateContext = createContext<FormStateContextType>({
  hasUnsavedChanges: false,
} as FormStateContextType)

export default FormStateContext
