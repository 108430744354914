import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { RealmSelect } from 'app/frontend/base/components'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z, ZodType } from 'zod'
import { transferCharacter } from 'app/frontend/base/api/character'
import GenericFormError from 'app/frontend/base/components/GenericFormError'
import { awaitPendingJob, displayErrorToast } from 'app/frontend/base/helpers'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { useQueryClient } from '@tanstack/react-query'

const zodRealm = z
  .object({
    id: z.number(),
    name: z.string(),
    nameWithRegion: z.string(),
    region:
      z.literal('EU') || z.literal('US') || z.literal('KR') || z.literal('TW'),
    slug: z.string(),
  })
  .required() as ZodType<wowaudit.Realm>

const createFormSchema = (_character: wowaudit.Character) =>
  z.object({
    name: z
      .string()
      .min(2)
      .max(12)
      .regex(/^\S*$/, 'Name must not contain spaces'),
    realm: zodRealm,
  })
// There are edge cases where a faction change happened that the user needs to manually
// transfer the character to the new entity, despite the name/realm being the same.
// .refine(
//   (data) => {
//     return (
//       data.name !== character.name || data.realm.id !== character.realm.id
//     )
//   },
//   {
//     message: 'You cannot transfer a character to itself',
//     path: ['genericError'],
//   },
// )

interface Props {
  character: wowaudit.Character
  setOpen: (open: boolean) => void
}

const TransferModal = (props: React.PropsWithChildren<Props>) => {
  const [submitting, setSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const [error, setError] = useState<string>()
  const { t } = useTranslation()
  const formSchema = createFormSchema(props.character)

  const form = useForm<z.infer<typeof formSchema> & { genericError?: string }>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.character.name,
      realm: props.character.realm,
    },
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setError(undefined)
    setSubmitting(true)
    transferCharacter(props.character.id, values)
      .then(async (response) => {
        awaitPendingJob(response.job_id, async (result) => {
          if (result.error) {
            setError(t(result.error))
          } else {
            await queryClient.invalidateQueries({ queryKey: ['pageInfo'] })

            toast.success(t('profile.character-transferred'))
            props.setOpen(false)
          }

          setSubmitting(false)
        })
      })
      .catch((response) => {
        displayErrorToast(response, t)
        setSubmitting(false)
        setError(t('errors.fallback_description'))
      })
  }

  return (
    <Dialog open={true} onOpenChange={props.setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Mark {props.character.name} as transferred</DialogTitle>
          <DialogDescription>
            Link the transferred character with the new in-game character to
            ensure historical wowaudit data doesn&apos;t get lost.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} id='transfer-form'>
            <div className='flex gap-2'>
              <FormItem>
                <FormLabel>New realm</FormLabel>
                <RealmSelect
                  className='flex'
                  realm={form.watch('realm')}
                  setRealm={(realm) => {
                    if (realm) form.setValue('realm', realm)
                  }}
                  inModal={true}
                />
              </FormItem>

              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem className='grow'>
                    <FormLabel>New name</FormLabel>
                    <FormControl>
                      <Input placeholder='Character name' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <GenericFormError form={form} customError={error} />
          </form>
        </Form>

        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Cancel
            </Button>
          </DialogClose>
          <Button
            type='submit'
            form='transfer-form'
            loading={submitting}
            spinner='blizzard-white'
          >
            Transfer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default TransferModal
