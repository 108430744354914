import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  descriptionKey: string
  onCancel: () => void
  onContinue: () => void
}

const ConfirmDialog = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()

  return (
    <AlertDialog open={props.open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('shared.are-you-sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t(props.descriptionKey)}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={props.onCancel}>
            {t('shared.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={props.onContinue}>
            {t('shared.continue')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ConfirmDialog
