import React, { useEffect, useState } from 'react'

interface Props {
  id?: number
}

const ItemIcon = ({ id }: Props) => {
  const [icon, setIcon] = useState<string | null>(null)

  useEffect(() => {
    if (id) {
      fetch(`https://nether.wowhead.com/tooltip/item/${id}`).then(
        async (response) => {
          const json = await response.json()
          setIcon(json.icon.replaceAll('-', ''))
        },
      )
    }
  }, [])

  return (
    <figure className='w-5 h-5'>
      <img
        className={`w-full h-full duration-300 ${
          icon ? 'opacity-100' : 'opacity-0'
        } transition-opacity`}
        src={
          icon
            ? `https://render.worldofwarcraft.com/us/icons/36/${icon}.jpg`
            : undefined
        }
      />
    </figure>
  )
}

export default ItemIcon
