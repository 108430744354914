import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import PulseLoader from 'react-spinners/PulseLoader'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-5 rounded-md py-1 px-3 text-xs',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  spinner?: 'pulse' | 'blizzard' | 'blizzard-white'
}

const BlizzardLoader = (type: 'primary' | 'white') => {
  return (
    <span className='absolute w-full flex justify-center left-0 top-[0.2rem]'>
      <div className={`blizzard-spinner ${type} size24`}>
        <div className={`${type} spinner-circle`} />
      </div>
    </span>
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      disabled: buttonDisabled,
      loading,
      spinner = 'pulse',
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    const disabled = loading || buttonDisabled
    const loaderComponent =
      spinner === 'pulse' ? (
        <PulseLoader
          loading={true}
          speedMultiplier={0.65}
          size={10}
          color={variant === 'default' ? 'white' : 'hsl(var(--primary))'}
          className='absolute'
        />
      ) : (
        BlizzardLoader(spinner === 'blizzard' ? 'primary' : 'white')
      )

    return (
      <Comp
        className={`${cn(buttonVariants({ variant, size, className }))} ${
          loading ? 'relative' : 'disabled:opacity-50'
        }`}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {loading && loaderComponent}
        {loading ? <span className='invisible'>{children}</span> : children}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
