import React from 'react'
import { tv } from 'tailwind-variants'

const tileStyles = tv({
  slots: {
    base: 'bg-muted rounded-md',
    header: 'whitespace-nowrap',
  },
  variants: {
    size: {
      small: {
        base: 'ml-4 mb-3 py-1 px-3 min-w-[4rem] flex-col whitespace-nowrap items-center text-center',
        header: 'uppercase text-xs opacity-70',
      },
      medium: {
        base: 'py-2 px-4 text-sm',
        header: 'mb-2 pb-1 block border-b border-accent font-bold',
      },
    },
    background: {
      muted: {
        base: 'bg-muted',
        header: '',
      },
      accent: {
        base: 'bg-accent',
        header: '',
      },
    },
  },
})

interface Props {
  title: string
  className?: string
  innerClassName?: string
  dataPresent?: boolean
  background?: keyof typeof tileStyles.variants.background
  size?: keyof typeof tileStyles.variants.size
}

const DashboardTile = ({
  children,
  title,
  className = '',
  innerClassName = '',
  size = 'small',
  background = 'muted',
  dataPresent = true,
}: React.PropsWithChildren<Props>) => {
  const { base, header } = tileStyles({ background, size })

  return (
    <div className={`${base()} ${background} ${className}`}>
      <span className={header()}>{title}</span>
      <div
        className={`${innerClassName} duration-300 ${
          dataPresent ? 'opacity-100' : 'opacity-0'
        } transition-opacity`}
      >
        {children}
      </div>
    </div>
  )
}

export default DashboardTile
