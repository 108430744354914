import { PointDistribution } from 'app/frontend/types/enums/PremiumBenefitCategory'
import React from 'react'

interface Props {
  orientation?: 'horizontal' | 'vertical'
  steps: number
  pointsSpent: PointDistribution
  previousPoints: number
  onClick?: (value: number) => void
  blockContent?: (i: number, active: boolean) => React.ReactNode
}

const PremiumBenefitBar = ({
  orientation = 'horizontal',
  steps,
  pointsSpent,
  previousPoints,
  onClick,
  blockContent,
}: Props) => {
  return (
    <div
      className={`my-4 flex ${
        orientation === 'vertical' ? 'flex-col grow' : 'w-full h-8'
      } rounded-lg gap-0.5`}
    >
      {Array.from({ length: steps }, (_, i) => i).map((i) => {
        const borderStyles = `${
          i === 0
            ? orientation === 'vertical'
              ? 'rounded-t-lg'
              : 'rounded-l-lg'
            : ''
        } ${
          i === steps - 1
            ? orientation === 'vertical'
              ? 'rounded-b-lg'
              : 'rounded-r-lg'
            : ''
        }`

        const active = pointsSpent.otherOrLegacy + pointsSpent.own > i
        const decreased = pointsSpent.own - previousPoints < 0
        const distance = Math.abs(
          i -
            (decreased ? -1 : 0) -
            (pointsSpent.otherOrLegacy + previousPoints),
        )
        const stepDuration =
          200 / (Math.abs(pointsSpent.own - previousPoints) || 1)

        return (
          <div
            key={i}
            className={`relative grow z-30 ${
              blockContent ? 'flex items-center' : ''
            } ${borderStyles} ${
              onClick
                ? pointsSpent.otherOrLegacy > i
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
                : ''
            }`}
            onClick={() =>
              onClick
                ? onClick(i + (active ? 0 : 1) - pointsSpent.otherOrLegacy)
                : null
            }
          >
            <span
              className={`${borderStyles} z-10 bg-accent absolute inset-x-0 inset-y-0 ${
                orientation === 'vertical' ? 'w-8' : ''
              }`}
            />

            <span
              className={`absolute z-20 ${
                orientation === 'vertical'
                  ? 'top-0 inset-x-0 w-8'
                  : 'left-0 inset-y-0'
              } ease-linear ${
                active
                  ? orientation === 'vertical'
                    ? 'bottom-0'
                    : 'right-0'
                  : orientation === 'vertical'
                  ? 'bottom-full'
                  : 'right-full'
              } ${
                pointsSpent.otherOrLegacy > i ? 'bg-green-200' : 'bg-primary'
              } ${borderStyles}`}
              style={{
                transitionDelay: `${distance * stepDuration}ms`,
                transitionProperty:
                  orientation === 'vertical' ? 'bottom' : 'right',
                transitionDuration: `${stepDuration}ms`,
              }}
            />

            {blockContent ? blockContent(i, active) : null}
          </div>
        )
      })}
    </div>
  )
}

export default PremiumBenefitBar
