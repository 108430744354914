import React from 'react'
import { Button } from '@/components/ui/button'
import PremiumModal from 'app/frontend/base/components/PremiumModal'
import SubscriptionInfo from './SubscriptionInfo'
import LegacySubscriptionInfo from './LegacySubscriptionInfo'
import { usePageInfo } from 'app/frontend/base/config/queries'
import { useSubscription } from 'app/frontend/base/helpers'
import RefreshPatreonButton from 'app/frontend/base/components/RefreshPatreonButton'

const PremiumStatus = () => {
  const {
    data: { user },
  } = usePageInfo()

  const patreonAccount = user?.linkedAccounts.find(
    (account) => account.displayType === 'Patreon',
  )

  const {
    subscription,
    legacySubscription,
    subscriptionColor,
    subscriptionActive,
  } = useSubscription(user)

  return (
    <div
      className={`bg-muted py-4 px-5 my-2 rounded-md border-2 ${subscriptionColor.border} max-w-[50rem]`}
    >
      {subscription?.active && subscription.tierName ? (
        <SubscriptionInfo
          subscription={subscription}
          color={subscriptionColor.text}
        />
      ) : legacySubscription?.details.pledge_tier ? (
        <LegacySubscriptionInfo
          subscription={legacySubscription}
          color={subscriptionColor.text}
        />
      ) : (
        <div>
          <h3 className='mb-4 font-bold'>Not subscribed</h3>
          {patreonAccount ? (
            <p className='text-sm'>
              No active pledge was found on your Patreon account. If this is
              incorrect, please reach out on Discord.
            </p>
          ) : (
            <p className='text-sm'>
              If you have a pledge on Patreon, please{' '}
              <a href='/auth/patreon' className='text-primary'>
                connect
              </a>{' '}
              your Patreon account.
            </p>
          )}
        </div>
      )}

      <div className='mt-4 flex gap-2'>
        <PremiumModal>
          <Button variant='outline'>
            {subscriptionActive ? 'Change subscription' : 'Subscribe now'}
          </Button>
        </PremiumModal>

        {patreonAccount ? <RefreshPatreonButton /> : null}
      </div>
    </div>
  )
}

export default PremiumStatus
