import React, { useState } from 'react'
import NotificationModal from '../NotificationModal'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

const newNotification: Partial<wowaudit.NotificationTemplate> = {
  cooldown: 7,
  criteria: { events: [] },
  ctaTarget: 'subject_page',
  delayInMinutes: 0,
  deliveryMethod: 'discord_message',
}

const NotificationSection = () => {
  const { t } = useTranslation()
  const [openTemplate, setOpenTemplate] = useState<
    Partial<wowaudit.NotificationTemplate> | undefined
  >()

  return (
    <div>
      <Button
        size='sm'
        onClick={() => setOpenTemplate(cloneDeep(newNotification))}
      >
        {t('shared.create-new')}
      </Button>

      <NotificationModal
        key={openTemplate?.id ?? 'new'}
        template={openTemplate ?? {}}
        open={!!openTemplate}
        setOpen={(_value: boolean) => setOpenTemplate(undefined)}
      />
    </div>
  )
}

export default NotificationSection
