import React, { PropsWithChildren, useState } from 'react'
import { Main } from '../../components'
import { Link, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import {
  useBlizzardApi,
  useLogo,
  usePrefetchedData,
  useRefreshData,
} from '../../helpers'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import GuildHeader from './components/GuildHeader'
import { useGuildDetails, useTeamDetails } from '../../config/queries'
import { Button } from '@/components/ui/button'
import { updateGuild } from '../../api/guild'
import GuildContext from '../../config/context/guild'
import TeamContext from '../../config/context/team'

interface Props {
  scope: 'guild' | 'team'
}

// When navigating directly, guildInfo will be populated (if the guild exists). When navigating
// from another page that contains a guild reference, propagatedInfo will be populated. If neither exist,
// the visitor can choose to search for the guild on the Blizzard API (blizzardInfo).
const GuildDashboard = ({ children, scope }: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  const { Logo } = useLogo()

  const {
    uriRegion,
    uriRealm,
    uriName,
    blizzardInfo,
    blizzardStatus,
    searchBlizzard,
  } = useBlizzardApi<wowaudit.Guild>('Guild', t)

  const prefetchedInfo = usePrefetchedData<wowaudit.Guild>('Guild')
  const propagatedInfo = useLocation().state?.guild as
    | wowaudit.Guild
    | undefined
  const baseGuild = {
    ...(blizzardInfo || {}),
    ...(prefetchedInfo || {}),
    ...(propagatedInfo || {}),
  } as Partial<wowaudit.Guild>

  const { refetch, data: extendedGuild } = useGuildDetails(baseGuild?.id)
  const guild = extendedGuild || baseGuild

  const prefetchedTeam = usePrefetchedData<wowaudit.Team>('Team')
  const propagatedTeam = useLocation().state?.team as wowaudit.Team | undefined
  const [currentTeamId, setCurrentTeamId] = useState(
    (propagatedTeam || prefetchedTeam)?.id,
  )

  const { data: currentTeam } = useTeamDetails(currentTeamId)

  const { refreshing, refreshData, updateTriggeredAt } = useRefreshData({
    retriever: updateGuild,
    id: guild.id,
    successKey: 'dashboard.guild.guild-update-success',
    callback: refetch,
    queryToInvalidate: ['guild', { id: guild.id }],
  })

  return (
    <Main>
      <GuildContext.Provider value={{ guild, currentTeam, setCurrentTeamId }}>
        <GuildHeader
          uriName={uriName}
          uriRegion={uriRegion}
          uriRealm={uriRealm}
        />

        {guild?.id && !guild.exists ? (
          <div className='mt-8'>
            <Alert variant='warning'>
              <AlertTitle>{t('dashboard.guild.no-longer-exists')}</AlertTitle>
              <AlertDescription className='mt-4'>
                <p>
                  <Trans
                    i18nKey='dashboard.guild.no-longer-exists-description'
                    components={[
                      <Link
                        key='settings'
                        to={`${guild.path}/settings`}
                        className='text-primary'
                      />,
                    ]}
                  />
                </p>

                <Button
                  variant='outline'
                  size='sm'
                  loading={refreshing}
                  spinner='blizzard'
                  className='flex items-center mt-4 gap-3 min-w-[7.5rem] text-foreground'
                  disabled={!guild?.id || !!updateTriggeredAt}
                  onClick={refreshData}
                >
                  <Logo className='w-6 h-6' />
                  {updateTriggeredAt
                    ? t('dashboard.guild.not-found')
                    : t('shared.refresh')}
                </Button>
              </AlertDescription>
            </Alert>
          </div>
        ) : null}
      </GuildContext.Provider>

      {!guild?.id ? (
        <div className='mt-8'>
          <Alert variant='warning'>
            <AlertTitle>{t('dashboard.guild.not-found')}</AlertTitle>
            <AlertDescription className='mt-4'>
              <p>{t('dashboard.guild.not-found-description')}</p>
              <Button
                className='mt-2 text-foreground'
                variant='outline'
                loading={blizzardStatus === 'pending'}
                disabled={blizzardStatus === 'not-found'}
                spinner='blizzard'
                onClick={searchBlizzard}
              >
                {t(
                  blizzardStatus === 'not-found'
                    ? 'dashboard.guild.not-found'
                    : `header.search-official-api`,
                )}
              </Button>
            </AlertDescription>
          </Alert>
        </div>
      ) : (
        <div className='my-6'>
          {scope === 'guild' ? (
            <GuildContext.Provider
              value={{ guild, currentTeam, setCurrentTeamId }}
            >
              {children}
            </GuildContext.Provider>
          ) : !!currentTeam ? (
            <TeamContext.Provider value={{ guild, team: currentTeam }}>
              {children}
            </TeamContext.Provider>
          ) : null}
        </div>
      )}
    </Main>
  )
}

export default GuildDashboard
