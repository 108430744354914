import { usePageInfo } from 'app/frontend/base/config/queries'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  character?: wowaudit.Character
}

const colorByTrack = {
  Myth: 'legendary',
  Hero: 'epic',
  Champion: 'rare',
  Veteran: 'common',
  Adventurer: 'common',
  Explorer: 'common',
  None: 'common',
}

const VaultSummary = ({ character }: Props) => {
  const { t } = useTranslation()
  const {
    data: { seasonData },
  } = usePageInfo()

  return (
    <div className='grid gap-1 grid-cols-3'>
      {(character?.details?.vaultSlots || Array.from({ length: 9 })).map(
        (slot, index) => {
          const track =
            seasonData.track_cutoffs.find((track) => track.ilvl <= slot)
              ?.track || 'Veteran'

          return (
            <div
              key={index}
              className='bg-accent py-1 px-2 rounded-md flex flex-col whitespace-nowrap text-center'
            >
              <div className='text-xs mb-1 overflow-hidden text-ellipsis'>
                {t(`dashboard.character.vault-summary-slot-${index + 1}`)}
              </div>
              {slot ? (
                <strong className={`text-quality-${colorByTrack[track]}`}>
                  {slot}
                </strong>
              ) : (
                <strong>-</strong>
              )}
            </div>
          )
        },
      )}
    </div>
  )
}

export default VaultSummary
