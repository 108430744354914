import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ChevronsUpDown, Plus } from 'lucide-react'
import { useGuild } from 'app/frontend/base/config/context/guild'
import { useTranslation } from 'react-i18next'

const TeamSwitcher = () => {
  const { t } = useTranslation()
  const { guild, currentTeam, setCurrentTeamId } = useGuild()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <span className='inline-flex cursor-pointer text-sm items-center font-bold'>
          {currentTeam ? (
            <span className=''>
              {t('shared.team')} {currentTeam.name}
            </span>
          ) : (
            <span className='opacity-50'>
              {t('dashboard.team.choose-team')}
            </span>
          )}
          <ChevronsUpDown className='ml-2' width={14} />
        </span>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className='min-w-[--radix-dropdown-menu-trigger-width]'
        align='start'
      >
        {(guild?.teams || []).map((team) => (
          <DropdownMenuItem
            key={team.id}
            onSelect={() => setCurrentTeamId(team.id)}
          >
            <span className={team.id === currentTeam?.id ? 'text-primary' : ''}>
              {team.name}
            </span>
          </DropdownMenuItem>
        ))}

        <DropdownMenuSeparator />

        <DropdownMenuItem className='flex gap-1 items-center'>
          <Plus width={14} />
          {t('dashboard.team.create-new')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default TeamSwitcher
