import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putRequest } from '../api'
import { displayErrorToast } from '../helpers'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

export const useUpdateEntity = <T>(entityType: string, id?: number) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: RecursivePartial<T>) =>
      putRequest(`/api/${entityType}s/${id}`, { [entityType]: data }),
    onSuccess: (data: RecursivePartial<T>) => {
      queryClient.setQueryData([entityType, { id }], data)
      toast.success(t('shared.changes-saved'))
    },
    onError: (error) => displayErrorToast(error, t),
  })
}
