import React from 'react'

import { Main } from '../../components'

const Onboarding = () => {
  return (
    <Main>
      <div>Onboarding!</div>
    </Main>
  )
}

export default Onboarding
