import React from 'react'
import { Main } from '../../components'
import { buttonVariants } from '@/components/ui/button'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SearchBar from '../../components/SearchBar'
import { usePageInfo } from '../../config/queries'

const Home = () => {
  const {
    data: { user },
  } = usePageInfo()
  const { t } = useTranslation()

  return (
    <Main>
      <div className='flex flex-col justify-center w-full items-center gap-8'>
        <h1 className='text-5xl font-bold max-w-3xl text-center leading-tight'>
          <Trans
            i18nKey='homepage.title'
            components={{ primary: <span className='text-primary' /> }}
          />
        </h1>
        {user ? (
          <Link
            className={`${buttonVariants()} text-xl px-8 py-8`}
            to='/dashboard'
          >
            {t('homepage.go-to-dashboard-button')}
          </Link>
        ) : (
          <a
            className={`${buttonVariants()} text-xl px-8 py-8`}
            href='/auth/bnet'
          >
            {t('homepage.log-in-button')}
          </a>
        )}
      </div>

      <div className='p-10 max-w-[44rem] mx-auto my-0'>
        <SearchBar placeholder={t('homepage.search-bar-text')} type='inline' />
      </div>
    </Main>
  )
}

export default Home
