import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import baseTranslationEN from '../../../locale/base/en.json'
import dashboardTranslationEN from '../../../locale/dashboard/en.json'
import errorsTranslationEN from '../../../locale/errors/en.json'
import homepageTranslationEN from '../../../locale/homepage/en.json'
import notificationsTranslationEN from '../../../locale/notifications/en.json'
import premiumTranslationEN from '../../../locale/premium/en.json'
import profileTranslationEN from '../../../locale/profile/en.json'
import settingsTranslationEN from '../../../locale/settings/en.json'

const resources = {
  en: {
    translation: {
      ...baseTranslationEN,
      dashboard: dashboardTranslationEN,
      errors: errorsTranslationEN,
      homepage: homepageTranslationEN,
      notifications: notificationsTranslationEN,
      premium: premiumTranslationEN,
      profile: profileTranslationEN,
      settings: settingsTranslationEN,
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
