import React from 'react'
import { Link } from 'react-router-dom'
import { CharacterClassIcon } from '../../../components'
import { mediaUrl } from '../../../helpers'
import { useTranslation } from 'react-i18next'
import DashboardTile from '../../../components/DashboardTile'

interface Props {
  character?: wowaudit.Character
}

// TODO: Separate by gender?
const offsetByRace: { [key: string]: { size: string; offset: string } } = {
  Human: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_34%]' },
  Orc: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_36%]' },
  Dwarf: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_41%]' },
  'Night Elf': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_27%]' },
  Undead: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_41%]' },
  Tauren: { size: 'bg-[length:35rem]', offset: 'bg-[center_top_30%]' },
  Gnome: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_55%]' },
  Troll: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_35%]' },
  Goblin: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_49%]' },
  'Blood Elf': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_39%]' },
  Draenei: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_28%]' },
  Worgen: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_36%]' },
  'Pandaren N': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_29%]' },
  'Pandaren A': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_29%]' },
  'Pandaren H': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_29%]' },
  Nightborne: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_34%]' },
  'Highmountain Tauren': {
    size: 'bg-[length:30rem]',
    offset: 'bg-[center_top_22%]',
  },
  'Void Elf': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_39%]' },
  'Lightforged Draenei': {
    size: 'bg-[length:45rem]',
    offset: 'bg-[center_top_30%]',
  },
  'Zandalari Troll': {
    size: 'bg-[length:45rem]',
    offset: 'bg-[center_top_24%]',
  },
  'Kul Tiran': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_24%]' },
  'Dark Iron Dwarf': {
    size: 'bg-[length:45rem]',
    offset: 'bg-[center_top_41%]',
  },
  Vulpera: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_46%]' },
  "Mag'har Orc": { size: 'bg-[length:45rem]', offset: 'bg-[center_top_36%]' },
  Mechagnome: { size: 'bg-[length:45rem]', offset: 'bg-[center_top_52%]' },
  'Dracthyr A': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_27%]' },
  'Dracthyr H': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_27%]' },
  '': { size: 'bg-[length:45rem]', offset: 'bg-[center_top_50%]' },
}

interface Props {
  character?: wowaudit.Character
  uriRegion: string
  uriRealm: string
  uriName: string
}

const CharacterHeader = ({
  character,
  uriRegion,
  uriRealm,
  uriName,
}: Props) => {
  const uriLinkFragment = `${uriRegion}/${uriRealm}/${uriName}`
  const { t } = useTranslation()
  const offset = offsetByRace[character?.mediaZone ? character.race : 'Human']

  return (
    <header className='border-b-2 ml-[-2rem] mr-[-2rem]'>
      <div className='flex items-end mt-[-2rem] ml-[2rem] mr-[2rem]'>
        <figure
          className={`w-[14rem] h-[7rem] ${offset?.size} ${offset?.offset} scale-x-[-1]`}
          style={{
            backgroundImage: `url(${mediaUrl(character, 'main-raw')})`,
          }}
        ></figure>

        <div className='grow mb-2'>
          <h1>
            <span className='font-bold text-[2rem] capitalize'>
              {character?.name || uriName.slice(0, 12)}
            </span>
            {character?.guild ? (
              <Link
                className={`text-sm ml-4 ${
                  character.guild.faction === 'Alliance'
                    ? 'text-faction-alliance'
                    : 'text-faction-horde'
                }`}
                state={{ guild: character.guild }}
                to={character.guild.path}
              >
                &lt;{character.guild.name}&gt;
              </Link>
            ) : null}
          </h1>
          <span className={`flex items-center gap-1 text-sm`}>
            <CharacterClassIcon characterClass={character?.characterClass} />
            <strong
              className={`ml-1 min-w-[1.2rem] duration-300 ${
                character?.level ? 'opacity-100' : 'opacity-0'
              } transition-opacity`}
            >
              {character?.level}
            </strong>
            <span className='ml-0.5'>
              {character?.characterClass || t('shared.unknown')}
            </span>
            <span className='ml-1 mr-1'> - </span>
            <span className='capitalize'>
              {character?.realm.nameWithRegion ||
                `${uriRealm} (${uriRegion.toUpperCase()})`}
            </span>
          </span>
        </div>

        <DashboardTile
          innerClassName='h-[2rem]'
          title='achievements'
          dataPresent={!!character?.details}
        >
          <div>{character?.details?.achievementPoints || '-'}</div>
        </DashboardTile>

        <DashboardTile
          innerClassName='h-[2rem]'
          title='m+ score'
          dataPresent={!!character?.details}
        >
          <div>{character?.details?.mythicRating || '-'}</div>
        </DashboardTile>

        <DashboardTile
          innerClassName='h-[2rem]'
          title='links'
          dataPresent={true}
        >
          <div className='flex gap-2'>
            <a
              href={
                character?.blizzardUrl ||
                `https://worldofwarcraft.blizzard.com/en-gb/character/${uriLinkFragment}`
              }
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://data.wowaudit.com/img/wow-logo.png'
                className='w-6'
              />
            </a>
            <a
              href={
                character?.wclUrl ||
                `https://www.warcraftlogs.com/character/${uriLinkFragment}`
              }
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://data.wowaudit.com/img/wcl-logo.png'
                className='w-6'
              />
            </a>
            <a
              href={
                character?.raiderioUrl ||
                `https://raider.io/characters/${uriLinkFragment}`
              }
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://data.wowaudit.com/img/raiderio-logo.png'
                className='w-6'
              />
            </a>
          </div>
        </DashboardTile>
      </div>
    </header>
  )
}

export default CharacterHeader
