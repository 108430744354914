import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import UserBenefitsBar from './UserBenefitsBar'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { revokeLegacyBenefits } from 'app/frontend/base/api/user'
import { displayErrorToast } from 'app/frontend/base/helpers'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  subscription: wowaudit.LinkedAccount
  color: string
}

const LegacySubscriptionInfo = ({ subscription, color }: Props) => {
  const [revoking, setRevoking] = useState<number>()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return (
    <div>
      <h3>
        You are subscribed to the legacy{' '}
        <strong className={`${color}`}>
          {subscription.details.pledge_tier}
        </strong>{' '}
        plan
      </h3>

      <UserBenefitsBar
        points={subscription.details.guild_limit}
        pointSpentPerEntity={subscription.details.beneficiaries.map((_) => 1)}
        label=''
      />

      <div className='bg-accent py-2 px-3 rounded-md'>
        <h3 className='mb-2'>Active benefits</h3>
        <ul className='text-sm'>
          {subscription.details.beneficiaries.length === 0 && (
            <p className='italic opacity-70'>
              {t('profile.premium.nothing-granted')}
            </p>
          )}
          {subscription.details.beneficiaries.map((beneficiary, i) => (
            <li
              key={i}
              className=' mb-2 flex justify-between bg-muted py-2 px-3 rounded-md'
            >
              <span>{beneficiary.name}</span>
              <div className='flex gap-2'>
                <Link
                  to={`${beneficiary.path}/premium`}
                  state={{
                    guild: {
                      id: beneficiary.id,
                      name: beneficiary.name,
                      path: beneficiary.path,
                    },
                  }}
                >
                  <Button variant='outline' size='xs'>
                    {t('shared.view')}
                  </Button>
                </Link>
                <Button
                  variant='outline'
                  size='xs'
                  loading={revoking === beneficiary.id}
                  onClick={() => {
                    setRevoking(beneficiary.id)
                    revokeLegacyBenefits(
                      subscription.id,
                      beneficiary.id,
                      beneficiary.type,
                    )
                      .then(async () => {
                        await queryClient.invalidateQueries({
                          queryKey: ['pageInfo'],
                        })
                        setRevoking(undefined)
                      })
                      .catch((err) => {
                        displayErrorToast(err, t)
                        setRevoking(undefined)
                      })
                  }}
                >
                  {t('shared.revoke')}
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LegacySubscriptionInfo
