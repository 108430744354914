import React, { useEffect, useMemo } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { useLogo, useRefreshData } from '../../helpers'
import { refreshPersonalCharacters } from '../../api/user'
import { Main } from '../../components'
import ProfileHeader from './components/ProfileHeader'
import LinkedAccount from './components/LinkedAccount'
import CharacterTable from './components/CharacterTable'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'

import Timestamp from '../../components/Timestamp'
import ActiveRegions from './components/ActiveRegions'
import { AlertTriangle } from 'lucide-react'
import PremiumStatus from './components/PremiumStatus'
import { usePageInfo } from '../../config/queries'

const Profile = () => {
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()
  const [searchParams] = useSearchParams()

  const { refreshing, refreshData } = useRefreshData({
    retriever: refreshPersonalCharacters,
    queryToInvalidate: ['pageInfo'],
  })

  useEffect(() => {
    if (searchParams.get('newToken') === 'true') {
      const scrollY = parseInt(sessionStorage.getItem('scrollPosition') || '0')
      setTimeout(() => scrollTo({ top: scrollY, behavior: 'smooth' }), 500)
      history.replaceState({}, '', '/profile')
      refreshData()
    }

    sessionStorage.removeItem('scrollPosition')
  }, [])

  const { Logo } = useLogo()

  const actionableCharacters = useMemo(() => {
    return user?.characters.filter((character) => character.actionable) || []
  }, [user?.characters])

  const tokenExpired = user && dayjs(user.oauthExpiresAt).isBefore(dayjs())

  if (user) {
    return (
      <Main>
        <ProfileHeader />

        {actionableCharacters.length ? (
          <div className='mt-8'>
            <h2 className='text-2xl font-bold mb-4 flex items-center gap-2'>
              <AlertTriangle
                className='text-yellow-600 dark:text-yellow-500'
                size={28}
              />{' '}
              {t('profile.characters-requiring-action')}
            </h2>
            <CharacterTable
              actionable={true}
              characters={actionableCharacters}
            />
          </div>
        ) : null}

        <div className='mt-8'>
          <h2 className='text-2xl font-bold mb-4'>Linked accounts</h2>

          <LinkedAccount type='Battle.net' />
          <LinkedAccount type='Discord' />
          <LinkedAccount type='Google' />
          <LinkedAccount type='Warcraft Logs' />
          <LinkedAccount type='Patreon' />
        </div>

        <div className='mt-8'>
          <h2 className='text-2xl font-bold mb-4'>{t('shared.premium')}</h2>

          <PremiumStatus />
        </div>

        <div className='mt-8' id='characters'>
          <div className='flex items-end justify-between mb-4'>
            <h2 className='text-2xl font-bold'>Characters</h2>

            <div className='flex flex-col items-end'>
              <a
                href={
                  tokenExpired
                    ? `/auth/bnet?origin=${encodeURIComponent(
                        '/profile?newToken=true',
                      )}`
                    : undefined
                }
                onClick={
                  tokenExpired
                    ? () =>
                        sessionStorage.setItem(
                          'scrollPosition',
                          window.scrollY.toString(),
                        )
                    : refreshData
                }
              >
                <Button
                  variant='outline'
                  className='flex items-center gap-3 min-w-[9.5rem]'
                  loading={refreshing}
                  spinner='blizzard'
                >
                  <Logo className='w-6 h-6' />
                  {t('shared.update-now')}
                </Button>
              </a>

              <div className='flex items-center gap-4 mt-2'>
                <div className='flex gap-1 text-xs text-right'>
                  <span>{t('shared.last-updated')}</span>
                  <Timestamp time={user.characterDataRefreshedAt} />
                </div>

                <ActiveRegions
                  disabled={refreshing}
                  updateCharacters={tokenExpired ? () => null : refreshData}
                />
              </div>
            </div>
          </div>
          <CharacterTable actionable={false} characters={user.characters} />
        </div>
      </Main>
    )
  } else {
    return <Navigate to='/' />
  }
}

export default Profile
