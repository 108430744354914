import { zSetting } from 'app/frontend/base/helpers/forms'
import React from 'react'
import { z } from 'zod'
import FormWrapper from '../FormWrapper'
import SettingField from '../fields/SettingField'

const formSchema = z.object({
  settings: z.object({
    raidsRemindDiscordUsersBefore: zSetting(z.number().or(z.string())),
    raidsDiscordPostTimezone: zSetting(z.string()),
    raidsDeleteDiscordPostAfter: zSetting(z.number().or(z.string())),
  }),
})

const DiscordBotSection = () => {
  return (
    <FormWrapper formSchema={formSchema}>
      <SettingField name='raidsDiscordPostTimezone' />
      <SettingField name='raidsRemindDiscordUsersBefore' layout='box' />
      <SettingField name='raidsDeleteDiscordPostAfter' layout='box' />
    </FormWrapper>
  )
}

export default DiscordBotSection
