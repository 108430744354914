import { createContext, useContext } from 'react'

export interface GuildContextType {
  guild: Partial<wowaudit.Guild>
  currentTeam?: wowaudit.Team | null
  setCurrentTeamId: (teamId?: number) => void
}

export const GuildContext = createContext<GuildContextType>(
  {} as GuildContextType,
)

export const useGuild = () => {
  const context = useContext(GuildContext)
  if (context === undefined) {
    throw new Error('useGuild must be used within a GuildProvider')
  }

  return context
}

export default GuildContext
