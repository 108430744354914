import React from 'react'

const Main = (props: React.PropsWithChildren<unknown>) => {
  return (
    <main className='bg-accent min-h-[calc(100vh-57px-73px)]'>
      <div className='container mt-8 mb-10'>{props.children}</div>
    </main>
  )
}

export default Main
