export const premiumBenefitCategories = [
  'refresh_factor',
  'team_limit',
  'character_limit',
] as const

export type PointDistribution = { otherOrLegacy: number; own: number }

export type PointsSpentByCategory = {
  [K in PremiumBenefitCategory]: PointDistribution
}

type PremiumBenefitCategory = typeof premiumBenefitCategories[number]
export default PremiumBenefitCategory
